import * as React from "react";
import { useTranslation } from "react-i18next";
import { NavBarContainer } from "@containers/student/NavBar";
import { Footer } from "@components/site/Footer";
import { Button } from "@components/student/Button";
import { LayoutContainer } from "@components/student/LayoutContainer";
import { Link } from "@components/ui/Link";
import * as s from "./404.sss";
export const NotFoundPage = () => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: s.root },
        React.createElement("div", { className: s.fullHeight },
            React.createElement(NavBarContainer, { position: "static", theme: "primary-transparent" }),
            React.createElement(LayoutContainer, null,
                React.createElement("div", { className: s.code }, "404"),
                React.createElement("div", { className: s.title }, t("site~О ні!")),
                React.createElement("div", { className: s.subTitle }, t("site~Сторінки, яку ви шукаєте, не існує.")),
                React.createElement(Link, { to: "/" },
                    React.createElement(Button, { className: s.button, theme: "primaryOrange", size: "L" }, t("site~Повернутися на головну"))))),
        React.createElement(Footer, null)));
};

import React from "react";
import { useHistory } from "react-router-dom";
import * as s from "./TimerBookingTicket.sss";
function startTimer(duration, display, refresh) {
    let timer = duration;
    let minutes;
    let seconds;
    setInterval(() => {
        minutes = parseInt((timer / 60).toString(), 10);
        seconds = parseInt((timer % 60).toString(), 10);
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        seconds = seconds < 10 ? `0${seconds}` : seconds;
        display.textContent = `${minutes}:${seconds}`;
        if (--timer < 0) {
            timer = duration;
            refresh();
        }
    }, 1000);
}
export const TimerBookingTicket = () => {
    const history = useHistory();
    const tenMinutes = 10 * 60;
    React.useEffect(() => {
        const display = document.querySelector('#timer-booking-ticket');
        const backAction = () => history.goBack();
        startTimer(tenMinutes, display, backAction);
    }, []);
    return React.createElement("div", { className: s.root },
        React.createElement("span", { className: s.label }, "\u0417\u0430\u0431\u0440\u043E\u043D\u044C\u043E\u0432\u0430\u043D\u043E:"),
        React.createElement("div", { id: "timer-booking-ticket" }));
};

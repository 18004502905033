var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as classNames from "classnames";
import * as React from "react";
import { Field, useForm, useFormState } from "react-final-form";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button } from "@components/student/Button";
import { LabelInput } from "@components/ui/LabelInput";
import { Link } from "@components/ui/Link";
import { composeValidators, phoneNumberValidator, required, } from "@utils/validators";
import { sitePrivacyPolicy, thxPage } from "@urls";
import { Modal } from "@ui/Modal";
import * as s from "./TrialLessonModal.sss";
import { addClassLocationAnalitic } from "@/utils/analytics";
export const TrialLessonModal = (_a) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    const { t } = useTranslation();
    const form = useForm();
    const { submitError, submitting, submitSucceeded } = useFormState();
    const currentCity = localStorage.getItem("cityName");
    const history = useHistory();
    const onRequestClose = () => {
        form.reset();
        props.onRequestClose(null);
    };
    React.useEffect(() => {
        if (submitSucceeded) {
            history.push(thxPage);
        }
    }, [submitSucceeded]);
    return (React.createElement(Modal, Object.assign({}, props, { onRequestClose: onRequestClose, className: classNames(s.root, className), withClose: true }),
        React.createElement("form", { onSubmit: (e) => __awaiter(void 0, void 0, void 0, function* () {
                e.preventDefault();
                yield form.submit();
            }) },
            React.createElement("div", { className: s.title }, submitSucceeded
                ? t("site~Дякую! Ваше звернення отримано!")
                : t("site~Пробне заняття")),
            React.createElement("div", { className: classNames({ [s.hidden]: submitSucceeded }) },
                React.createElement(Field, { name: "firstName", validate: required, render: ({ input, meta }) => (React.createElement(LabelInput, Object.assign({ className: s.input, placeholder: t("site~Як вас звати?*"), autoComplete: "given-name" }, input, { error: (meta.touched && meta.error) || meta.submitError }))) }),
                React.createElement(Field, { name: "phoneNumber", validate: composeValidators([required, phoneNumberValidator]), render: ({ input, meta }) => (React.createElement(LabelInput, Object.assign({ className: s.input, placeholder: t("site~Ваш номер телефону*"), autoComplete: "tel" }, input, { error: (meta.touched && meta.error) || meta.submitError }))) }),
                React.createElement(Field, { name: "instagram", render: ({ input, meta }) => (React.createElement(LabelInput, Object.assign({ className: s.input, placeholder: t("site~Інстаграм"), autoComplete: "given-name" }, input, { error: (meta.touched && meta.error) || meta.submitError }))) })),
            React.createElement(Button, { type: "submit", "data-location": currentCity, className: classNames(s.submitButton, {
                    [s.hidden]: submitSucceeded,
                }, addClassLocationAnalitic(currentCity)), disabled: submitting || submitSucceeded },
                submitSucceeded ? t("site~Дякую") : t("site~Відправити"),
                submitting && "..."),
            submitError && React.createElement("div", { className: s.error }, submitError),
            !submitSucceeded && (React.createElement("div", { className: s.termsAccept },
                React.createElement(Trans, { ns: "site" },
                    "\u041D\u0430\u0442\u0438\u0441\u043A\u0430\u044E\u0447\u0438 \u0446\u044E \u043A\u043D\u043E\u043F\u043A\u0443 \u044F \u0434\u0430\u044E \u0437\u0433\u043E\u0434\u0443 \u043D\u0430 \u043E\u0431\u0440\u043E\u0431\u043A\u0443",
                    " ",
                    React.createElement(Link, { theme: "orange", to: sitePrivacyPolicy }, "\u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u0438\u0445 \u0434\u0430\u043D\u0438\u0445")))))));
};

import * as classNames from "classnames";
import * as React from "react";
import { Link } from "@components/ui/Link";
import ArrowToBottomIcon from "@svg/arrowToBottom.svg";
import * as s from "./NavItem.sss";
const themeClassName = {
    primary: s.themePrimary,
    "primary-transparent": s.themePrimaryTransparent,
    "bright-transparent": s.themeBrightTransparent,
    bright: s.themeBrightTransparent,
    purple: s.themePurple,
};
export const NavItem = ({ className, navItem, isActive, theme, onClick, }) => (React.createElement("div", { className: classNames(s.root, themeClassName[theme], className) }, "subNav" in navItem ? (React.createElement("div", { onClick: e => onClick(navItem.title) },
    navItem.title,
    React.createElement(ArrowToBottomIcon, { className: classNames(s.arrow, { [s.expanded]: isActive }) }),
    React.createElement("div", { className: classNames(s.subNav, { [s.visibleSubNav]: isActive }) }, navItem.subNav.map(link => (React.createElement(Link, { className: s.subItem, to: link.path, key: link.path, onClick: () => onClick(navItem.title, link.title) },
        link.iconImage ? (React.createElement("img", { className: classNames(s.iconImage, "lazyload", "lazypreload"), "data-srcset": `${link.iconImage.x1} 1x, ${link.iconImage.x2} 2x`, alt: "..." })) : null,
        link.title)))))) : (React.createElement(Link, { className: s.listLink, to: navItem.path, key: navItem.path, onClick: () => onClick && onClick() }, navItem.title))));

import { mainContactsHash, mainPage, newsPage, onlineLanding, siteAboutUs, siteOfflineLanding, siteSchedule, siteStudios, siteSubscriptionsOffline, studentPageMySchedule, studentPageSettings, studentPageSubscription, } from "@urls";
import ProfileImage1x from "@static/img/profile-55px-1x-min.png";
import ProfileImage2x from "@static/img/profile-55px-2x-min.png";
import ScheduleImage1x from "@static/img/schedule-55px-1x-min.png";
import ScheduleImage2x from "@static/img/schedule-55px-2x-min.png";
import SubscriptionImage1x from "@static/img/subscription-55px-1x-min.png";
import SubscriptionImage2x from "@static/img/subscription-55px-2x-min.png";
export const getStudentsNavigation = t => [
    {
        title: t("Заняття в студіях"),
        path: siteOfflineLanding,
    },
    {
        title: t("Заняття онлайн"),
        path: onlineLanding,
    },
    {
        title: t("Локації"),
        path: siteStudios,
    },
    {
        title: t("Абонементи"),
        path: siteSubscriptionsOffline,
    },
    {
        title: t("Розклад"),
        path: siteSchedule,
    },
    {
        title: t("Блог"),
        path: newsPage,
    },
    {
        title: t("Про нас"),
        path: siteAboutUs,
    },
];
export const getPersonalCabinet = (t) => ({
    title: t("Особистий кабінет"),
    subNav: [
        {
            title: t("Мій розклад"),
            path: studentPageMySchedule,
            iconImage: {
                x1: ScheduleImage1x,
                x2: ScheduleImage2x,
            },
        },
        {
            title: t("Мій абонемент"),
            path: studentPageSubscription,
            iconImage: {
                x1: SubscriptionImage1x,
                x2: SubscriptionImage2x,
            },
        },
        {
            title: t("Налаштування"),
            path: studentPageSettings,
            iconImage: {
                x1: ProfileImage1x,
                x2: ProfileImage2x,
            },
        },
        {
            title: t("login~Вийти"),
            path: "",
        },
    ],
});
export const getNavForLoggedIn = t => [
    {
        title: t("Мій розклад"),
        path: studentPageMySchedule,
    },
    {
        title: t("Мій абонемент"),
        path: studentPageSubscription,
    },
    {
        title: t("Налаштування"),
        path: studentPageSettings,
    },
    {
        title: t("Підтримка"),
        path: `${mainPage}${mainContactsHash}`,
    },
    {
        title: t("login~Вийти"),
        path: "",
    },
];

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as classNames from "classnames";
import * as React from "react";
import { Desktop } from "@components/student/NavBar/Desktop";
import { Mobile } from "@components/student/NavBar/Mobile";
import * as s from "./NavBar.sss";
import { useCitiesQuery, useLogoutMutation } from "@/graphql";
export const NavBar = ({ theme = "primary", position = "fixed", isLoggedIn, className, }) => {
    const { data: { cities }, loading } = useCitiesQuery();
    const [logout] = useLogoutMutation();
    const logoutUser = () => __awaiter(void 0, void 0, void 0, function* () {
        yield logout();
        window.location.reload();
    });
    if (loading) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Desktop, { className: classNames(s.desktop, className), isLoggedIn: isLoggedIn, logout: logoutUser, position: position, theme: theme, cities: cities }),
        React.createElement(Mobile, { className: classNames(s.mobile, className), isLoggedIn: isLoggedIn, logout: logoutUser, position: position, theme: theme, cities: cities })));
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as classNames from "classnames";
import LogoSVG from "static/svg/logo.svg";
import MedalSVG from "static/svg/medal.svg";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@components/student/Button";
import * as s from "./WelcomeModal.sss";
import { Modal } from "@/ui/Modal";
const switchContent = (step, t) => {
    switch (step) {
        case 1:
            return React.createElement("div", null, t("student~Первый вход шаг 1.1"));
        case 2:
            return React.createElement("div", null, t("student~Первый вход шаг 2"));
        case 3:
            return (React.createElement("div", null,
                t("student~Первый вход шаг 3.1"),
                React.createElement("br", null),
                React.createElement("br", null),
                t("student~Первый вход шаг 3.2")));
        case 4:
            return (React.createElement("div", null,
                t("student~Первый вход поздравление"),
                React.createElement("ul", { className: s.list },
                    React.createElement("li", null, t("student~Первый вход поздравление 1")),
                    React.createElement("li", null, t("student~Первый вход поздравление 2")),
                    React.createElement("li", null, t("student~Первый вход поздравление 3")),
                    React.createElement("li", null, t("student~Первый вход поздравление 4")))));
        default:
            break;
    }
};
export const WelcomeModal = (_a) => {
    var { className, onRequestClose } = _a, props = __rest(_a, ["className", "onRequestClose"]);
    const { t } = useTranslation();
    const [step, setStep] = React.useState(0);
    const cancelButton = () => {
        localStorage.setItem("welcome-helper", "false");
        onRequestClose(null);
    };
    const nextStep = () => {
        if (step > 3) {
            cancelButton();
        }
        else
            setStep(prev => ++prev);
    };
    return (React.createElement(Modal, Object.assign({}, props, { className: classNames(s.root, className) }),
        step === 0 ? (React.createElement("div", { className: s.container },
            React.createElement(LogoSVG, { className: s.logo }),
            React.createElement("h3", { className: s.title }, t("student~Bonjour mon amie!")),
            React.createElement("div", { className: s.textInfo }, t("student~Как пользоваться")))) : (React.createElement("div", { className: s.container },
            React.createElement("div", { className: s.titleContainer }, step > 3 ? (React.createElement("div", null,
                React.createElement(MedalSVG, { className: s.logo, style: { marginBottom: 15 } }),
                React.createElement("h3", { className: s.title }, t("student~Первый вход поздравление-заголовок")))) : (React.createElement(React.Fragment, null,
                React.createElement("h3", { className: s.title },
                    t("student~Шаг"),
                    " ",
                    step),
                "/ 3"))),
            React.createElement("div", { className: s.textContent }, switchContent(step, t)))),
        React.createElement("div", { className: s.buttons },
            step === 0 && (React.createElement(Button, { theme: "ghostOrange", className: s.cancelButton, onClick: cancelButton }, t("student~Не нужно"))),
            React.createElement(Button, { className: s.submitButton, onClick: nextStep }, step === 0
                ? t("student~Давай!")
                : step > 3
                    ? t("student~Ура!")
                    : t("student~Дальше")))));
};
export default WelcomeModal;

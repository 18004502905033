import dayjs from "dayjs";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Redirect, generatePath, useParams } from "react-router-dom";
import { CheckoutSummaryContainer } from "@containers/student/CheckoutSummary";
import { BasePage } from "@components/student/BasePage";
import { CheckoutScheduleInfo } from "@components/student/CheckoutScheduleInfo";
import { CheckoutSubscriptionInfo } from "@components/student/CheckoutSubscriptionInfo";
import { LayoutContainer } from "@components/student/LayoutContainer";
import { CheckoutType, ScheduleType } from "@constants";
import { siteConcert, siteEvents, studentSchedule, studentScheduleSelectSubscription, } from "@urls";
import useStudentCheckoutData from "@local-state/hooks/useStudentCheckoutData";
import * as s from "./CheckoutPage.sss";
import { TimerBookingTicket } from "@/containers/site/TimerBookingTicket/TimerBookingTicket";
export const CheckoutPage = () => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    dayjs.locale(i18n.language);
    const { scheduleType, lessonPurchaseId } = useParams();
    const [promoCodeData, setPromoCode] = React.useState(null);
    let schedule;
    const { selectedEvent, selectedConcert, lessonTemplates, subscription, trialLesson, } = useStudentCheckoutData();
    const isEvents = scheduleType === ScheduleType.Events;
    const isConcert = scheduleType === ScheduleType.Concerts;
    if (scheduleType === ScheduleType.Individual ||
        scheduleType === ScheduleType.Trial) {
        if (!lessonTemplates.length && !trialLesson.length) {
            return (React.createElement(Redirect, { to: generatePath(studentSchedule, {
                    scheduleType,
                    checkout: CheckoutType.Checkout,
                    lessonPurchaseId,
                }) }));
        }
        schedule = trialLesson.length ? trialLesson : lessonTemplates;
    }
    else if (isEvents) {
        if (!selectedEvent) {
            return React.createElement(Redirect, { to: siteEvents });
        }
        schedule = selectedEvent;
    }
    else if (isConcert) {
        if (!selectedConcert) {
            return React.createElement(Redirect, { to: siteConcert });
        }
        schedule = selectedConcert;
    }
    else {
        throw new Error("Unknown schedule type");
    }
    if (!subscription && !isEvents && !isConcert) {
        return React.createElement(Redirect, { to: generatePath(studentScheduleSelectSubscription) });
    }
    return (React.createElement(BasePage, { isThemeTransform: false, className: s.root },
        React.createElement(LayoutContainer, null,
            React.createElement("div", { className: s.title }, t("student~Оформление заказа")),
            React.createElement("div", { className: s.content },
                !isEvents && !isConcert ? (React.createElement("div", { className: s.leftSide },
                    React.createElement(CheckoutScheduleInfo, { className: s.scheduleInfo, schedule: schedule, lessonPurchaseId: subscription.id }),
                    React.createElement(CheckoutSubscriptionInfo, { className: s.subscriptionInfo, subscription: subscription, setPromoCode: promoCode => setPromoCode(promoCode) }))) : (React.createElement("div", { className: s.userInfo },
                    React.createElement("div", { className: s.info },
                        "\u0422\u0435\u043B\u0435\u0444\u043E\u043D: ",
                        schedule.phoneNumber),
                    React.createElement("div", { className: s.info },
                        "Email: ",
                        schedule.email),
                    schedule.tgOrInst && (React.createElement("div", { className: s.info },
                        "\u0422\u0433/\u0406\u043D\u0441\u0442: ",
                        schedule.tgOrInst)),
                    (schedule === null || schedule === void 0 ? void 0 : schedule.placeIds) && (React.createElement("div", { className: s.info },
                        "\u041A\u0456\u043B\u044C\u043A\u0456\u0441\u0442\u044C \u043C\u0456\u0441\u0446\u044C: ", schedule === null || schedule === void 0 ? void 0 :
                        schedule.placeIds.length)),
                    React.createElement(TimerBookingTicket, null))),
                isConcert && (React.createElement("div", { className: s.tickets },
                    React.createElement("div", { className: s.ticket },
                        React.createElement("div", null,
                            "\u0427\u0430\u0441: ",
                            dayjs(schedule.time).format("DD/MM/YYYY - HH:mm")),
                        React.createElement("div", null,
                            "\u041B\u043E\u043A\u0430\u0446\u0456\u044F: \u041A\u0438\u0457\u0432\u0441\u044C\u043A\u0430 \u043E\u043F\u0435\u0440\u0430, ",
                            schedule.hall.street),
                        schedule.users.map((user, index) => !user.rowsName ? null : (React.createElement("div", { key: index, className: s.userConcert },
                            React.createElement("div", null,
                                " \u0420\u044F\u0434: ",
                                user.rowsName),
                            React.createElement("div", null,
                                " \u041C\u0456\u0441\u0446\u0435: ",
                                user.seat),
                            React.createElement("div", null,
                                " \u0412\u0430\u0440\u0442\u0456\u0441\u0442\u044C \u043A\u0432\u0438\u0442\u043A\u0430: ",
                                user.price))))))),
                React.createElement("div", { className: s.rightSide },
                    isEvents && (React.createElement("div", { className: s.rightSide },
                        React.createElement(CheckoutSummaryContainer, { className: s.summary, lessonsCount: 1, isTrial: false, schedule: schedule, subscriptionId: schedule.eventId, promocodeId: promoCodeData === null || promoCodeData === void 0 ? void 0 : promoCodeData.id }))),
                    isConcert && (React.createElement("div", { className: s.rightSide },
                        React.createElement(CheckoutSummaryContainer, { className: s.summary, lessonsCount: 1, isTrial: false, schedule: schedule, subscriptionId: schedule.concertId, promocodeId: promoCodeData === null || promoCodeData === void 0 ? void 0 : promoCodeData.id }))),
                    !isEvents && !isConcert && (React.createElement(CheckoutSummaryContainer, { className: s.summary, lessonsCount: subscription.lessonsCount, subscriptionId: subscription.id, isTrial: false, schedule: schedule.__typename === "Group"
                            ? { groupId: schedule.id }
                            : {
                                lessonTemplatesIds: schedule.map(template => template.id),
                                groupId: schedule.id,
                            }, promocodeId: promoCodeData === null || promoCodeData === void 0 ? void 0 : promoCodeData.id })))))));
};
export default CheckoutPage;

import loadable from "@loadable/component";
import * as dayjs from "dayjs";
import "dayjs/locale/ru";
import "dayjs/locale/uk";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ErrorBoundary } from "@components/ui/ErrorBoundary";
import { NotFoundPage } from "@pages/common/404";
import { authBase, newsPage, studentCheckout, studentPage, teacherCabinet, } from "@urls";
import { LoginRequiredRoute } from "@ui/LoginRequiredRoute";
import "./BaseApp.sss";
import { ScheduleType } from "./constants";
import { CheckoutPage } from "./pages/student/CheckoutPage";
import { AppInitializer } from "./components/ui/AppInitializer";
if (typeof window !== "undefined") {
    require("lazysizes");
    const smoothscroll = require("smoothscroll-polyfill");
    smoothscroll.polyfill();
}
const LoadableTeacherRoutes = loadable(() => import("@routes/teacher"), {
    ssr: false,
});
const LoadableNewsRoutes = loadable(() => import("@routes/news"));
const LoadableStudentRoutes = loadable(() => import("@routes/student"), {
    ssr: false,
});
const LoadableAuthRoutes = loadable(() => import("@routes/auth"), { ssr: false });
const LoadableSiteRoutes = loadable(() => import("@routes/site"));
export const BaseApp = () => {
    const { i18n } = useTranslation();
    dayjs.locale(i18n.language);
    return (React.createElement(ErrorBoundary, null,
        React.createElement(AppInitializer, null,
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: ["/admin", "/admin/#"], component: loadable(() => import("@/admin/App"), { ssr: false }) }),
                React.createElement(LoginRequiredRoute, { path: teacherCabinet, component: LoadableTeacherRoutes }),
                React.createElement(Route, { path: newsPage, component: LoadableNewsRoutes }),
                React.createElement(Route, { path: studentCheckout, exact: true, render: props => {
                        if (props.match.params.scheduleType === ScheduleType.Events ||
                            props.match.params.scheduleType === ScheduleType.Concerts) {
                            return React.createElement(CheckoutPage, null);
                        }
                        return (React.createElement(LoginRequiredRoute, { path: studentPage, component: LoadableStudentRoutes }));
                    } }),
                React.createElement(LoginRequiredRoute, { path: studentPage, component: LoadableStudentRoutes }),
                React.createElement(Route, { path: authBase, component: LoadableAuthRoutes }),
                React.createElement(Route, { path: "/", component: LoadableSiteRoutes }),
                React.createElement(Route, { path: "*", component: NotFoundPage })))));
};
export default BaseApp;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "@components/ui/Input";
import CheckIcon from "@svg/check.svg";
import { Button } from "../Button";
import * as s from "./PromoCodeInput.sss";
export const PromoCodeInput = ({ onSubmit }) => {
    const { t } = useTranslation();
    const [couponCode, setCouponCode] = useState("");
    const [applied, setApplied] = useState(false);
    const [loading, setLoading] = useState(false);
    const [promoCode, setPromoCode] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const handleChange = (event) => {
        setCouponCode(event.target.value);
        setErrorMessage("");
    };
    const handleSubmit = (event) => __awaiter(void 0, void 0, void 0, function* () {
        event.preventDefault();
        setLoading(true);
        try {
            const { data } = yield onSubmit(couponCode);
            setApplied(true);
            setCouponCode("");
            setPromoCode(data.activatePromocode);
            setLoading(false);
        }
        catch (error) {
            setLoading(false);
            setErrorMessage(error.message);
        }
    });
    const removeCoupon = event => {
        event.preventDefault();
        onSubmit("");
        setApplied(false);
    };
    if (loading) {
        return React.createElement(React.Fragment, null, "Loading...");
    }
    return (React.createElement("form", { className: s.formContainer, onSubmit: handleSubmit },
        React.createElement("label", { className: s.label, htmlFor: "couponInput" }, "\u041F\u0440\u043E\u043C\u043E\u043A\u043E\u0434"),
        !applied ? (React.createElement("div", { className: s.inputWrapper },
            React.createElement(Input, { className: classNames(s.inputField, {
                    [s.error]: Boolean(errorMessage),
                }), type: "text", id: "couponInput", value: couponCode, onChange: handleChange, placeholder: t("student~Введите промокод") }),
            React.createElement(Button, { theme: "primaryOrange", className: s.submitButton, type: "submit" }, "+"))) : (React.createElement("div", { className: classNames(s.inputWrapper, s.applied) },
            React.createElement(CheckIcon, { className: s.checked }),
            React.createElement("div", { className: s.info },
                React.createElement("div", null, promoCode.text),
                React.createElement("div", { className: s.discount },
                    "-",
                    (promoCode.subscription.price * promoCode.procents) / 100,
                    "\u0433\u0440\u043D (",
                    " ",
                    promoCode.procents,
                    "% )")),
            React.createElement(Button, { theme: "clean", className: s.removeButton, type: "button", onClick: removeCoupon }, "\u0412\u0438\u0434\u0430\u043B\u0438\u0442\u0438")))));
};

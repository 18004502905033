import * as classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useHistory } from "react-router-dom";
import { Button } from "@components/student/Button";
import { ScheduleCard } from "@components/student/ScheduleCard";
import * as s from "./CheckoutScheduleInfo.sss";
import { CheckoutType, ScheduleType } from "@/constants";
import { studentTrialSchedule } from "@/urls";
export const CheckoutScheduleInfo = ({ schedule, lessonPurchaseId, className, }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const addSchedule = () => {
        history.push(generatePath(studentTrialSchedule, {
            checkout: CheckoutType.Checkout,
            scheduleType: ScheduleType.Trial,
            lessonPurchaseId,
        }));
    };
    const setAddChange = (newValue) => {
        setAddChange(newValue);
    };
    return (React.createElement("div", { className: classNames(s.root, className) },
        React.createElement("div", { className: s.title }, t("student~Расписание занятий")),
        Array.isArray(schedule) ? (schedule.map(lesson => (React.createElement(ScheduleCard, { key: lesson.id, lessonSchedule: lesson, addChange: false, setAddChange: setAddChange })))) : (React.createElement(ScheduleCard, { lessonSchedule: schedule, addChange: false, setAddChange: setAddChange })),
        React.createElement(Button, { className: s.changeScheduleButton, theme: "secondaryPurple", onClick: addSchedule }, t("student~Выбрать другое расписание"))));
};

import * as classNames from "classnames";
import * as React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Button } from "@components/student/Button";
import { NavItem } from "@components/student/NavBar/NavItem";
import { getStudentsNavigation, } from "@components/student/NavBar/constants";
import { Link } from "@components/ui/Link";
import { addClassPhoneAnalitic, safeFbq } from "@utils/analytics";
import { siteIndex, trialLessonModalHash, } from "@urls";
import FacebookIcon1x from "@static/img/facebook-rounded-1x-min.png";
import FacebookIcon2x from "@static/img/facebook-rounded-2x-min.png";
import InstagramIcon1x from "@static/img/instagram-rounded-1x-min.png";
import InstagramIcon2x from "@static/img/instagram-rounded-2x-min.png";
import TelegramIcon1x from "@static/img/telegram-rounded-1x-min.png";
import TelegramIcon2x from "@static/img/telegram-rounded-2x-min.png";
import TikTokIcon1x from "@static/img/tiktok-rounded-1x-min.png";
import TikTokIcon2x from "@static/img/tiktok-rounded-2x-min.png";
import MaryBalletTopIcon from "@svg/maryBalletRedesignLogo.svg";
import * as s from "./Mobile.sss";
const themeClassName = {
    primary: s.themePrimary,
    "primary-transparent": s.themePrimaryTransparent,
    "bright-transparent": s.themeBrightTransparent,
    bright: s.themeBright,
    purple: s.themePurple,
};
const positionClassName = {
    fixed: s.positionFixed,
    static: s.positionStatic,
};
export const Mobile = ({ theme, isLoggedIn, logout, position, cities, className, }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [activeItem, setActiveItem] = useState({});
    const studentsNavigation = getStudentsNavigation(t);
    const cityId = localStorage.getItem("cityId");
    const currentCity = localStorage.getItem("cityName");
    const currentLocation = cities.find(({ id }) => id.toString() === cityId);
    const selectNewCity = () => {
        localStorage.removeItem("cityChoised");
        window.location.reload();
    };
    return (React.createElement("div", { className: classNames(s.root, themeClassName[theme], positionClassName[position], { [s.active]: isOpen }, className) },
        isOpen && (React.createElement(Helmet, null,
            React.createElement("meta", { name: "theme-color", content: "#E46039" }))),
        React.createElement("div", { className: s.topLine },
            React.createElement(Link, { className: s.mbIconLink, to: siteIndex },
                React.createElement(MaryBalletTopIcon, { className: s.maryBalletIcon })),
            React.createElement("button", { type: "button", className: classNames(s.open, { [s.active]: isOpen }), onClick: () => setIsOpen(!isOpen) },
                React.createElement("span", { className: s.lines }))),
        React.createElement("div", { className: s.menu },
            React.createElement("nav", { className: s.nav },
                React.createElement("div", { className: s.topNav }, studentsNavigation.map(navItem => (React.createElement(NavItem, { key: navItem.title, theme: theme, navItem: navItem, isActive: "subNav" in navItem && activeItem[navItem.title], onClick: "subNav" in navItem
                        ? e => {
                            activeItem[e]
                                ? setActiveItem(Object.assign(Object.assign({}, activeItem), { [e]: false }))
                                : setActiveItem(Object.assign(Object.assign({}, activeItem), { [e]: true }));
                        }
                        : () => setIsOpen(false) })))),
                React.createElement("span", { className: s.currentCity, onClick: selectNewCity },
                    "\u041C\u0456\u0441\u0442\u043E ", currentLocation === null || currentLocation === void 0 ? void 0 :
                    currentLocation.title)),
            React.createElement(Link, { theme: "clean", to: trialLessonModalHash },
                React.createElement(Button, { theme: "ghostPurple", className: s.button }, t("student~Записатись на заняття"))),
            React.createElement("div", { className: s.footContainer },
                React.createElement("a", { className: classNames(s.phone, addClassPhoneAnalitic(currentCity)), "data-location": currentCity, href: `tel:+${currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.phoneNumber}`, onClick: () => safeFbq("track", "Contacts") }, currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.phoneNumber)),
            React.createElement("div", { className: s.socialIcons },
                React.createElement("a", { href: currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.instagram, target: "_blank" },
                    React.createElement("img", { className: "lazyload", "data-srcset": `${InstagramIcon1x} 1x, ${InstagramIcon2x} 2x`, alt: "intagram" })),
                React.createElement("a", { href: currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.telegram, target: "_blank" },
                    React.createElement("img", { className: "lazyload", "data-srcset": `${TelegramIcon1x} 1x, ${TelegramIcon2x} 2x`, alt: "telegram" })),
                React.createElement("a", { href: currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.facebook, target: "_blank" },
                    React.createElement("img", { className: "lazyload", "data-srcset": `${FacebookIcon1x} 1x, ${FacebookIcon2x} 2x`, alt: "facebook" })),
                React.createElement("a", { href: currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.tiktok, target: "_blank" },
                    React.createElement("img", { className: "lazyload", "data-srcset": `${TikTokIcon1x} 1x, ${TikTokIcon2x} 2x`, alt: "TikTok" }))))));
};

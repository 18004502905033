import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@components/student/Button";
import { Modal } from "@components/student/Modal";
import { TextRadioGroup } from "@components/student/TextRadioGroup";
import * as s from "./CheckoutStartDateInput.sss";
export const CheckoutStartDateInput = ({ availableDates, value, onChange, }) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const options = availableDates.map(date => ({
        label: date.format("DD MMMM YYYY"),
        value: date,
    }));
    const [localValue, setLocalValue] = useState(() => options.find(option => option.value.isSame(value)));
    return (React.createElement("div", { className: s.root },
        React.createElement("div", { className: s.startDateText },
            t(`student~Абонемент почне діяти з ${value.format("DD MMMM YYYY")}.`),
            availableDates.length > 1 && (React.createElement("button", { type: "button", className: s.changeDateButton, onClick: () => setIsModalOpen(true) }, t("student~Изменить")))),
        React.createElement(Modal, { className: s.modal, isOpen: isModalOpen, theme: "orange", withClose: true, onRequestClose: () => setIsModalOpen(false) },
            React.createElement("div", { className: s.modalTitle }, t("student~Начало действия абонемента")),
            React.createElement("div", { className: s.modalText }, t("student~Если вы хотите отложить начало действия абонемента, " +
                "выберите подходящую дату из списка ниже")),
            React.createElement(TextRadioGroup, { className: s.radioGroup, options: options, onChange: setLocalValue, value: localValue }),
            React.createElement(Button, { className: s.saveChanges, theme: "secondaryOrange", onClick: () => {
                    onChange(localValue.value);
                    setIsModalOpen(false);
                } }, t("student~Сохранить изменения")))));
};

import * as classNames from "classnames";
import * as React from "react";
import { Link as ReactRouterLink, } from "react-router-dom";
import * as s from "./Link.sss";
const themes = {
    violet: s.themeViolet,
    white: s.themeWhite,
    orange: s.themeOrange,
    helper: s.themeHelper,
    clean: "",
};
export function Link(props) {
    const { theme, className: classNameProps } = props;
    const className = classNames(s.root, themes[theme || "violet"], classNameProps);
    if ("href" in props) {
        return React.createElement("a", Object.assign({}, props, { className: className }));
    }
    return (React.createElement(ReactRouterLink, Object.assign({}, props, { className: className })));
}

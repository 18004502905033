import { ApolloProvider } from "@apollo/client";
import { loadableReady } from "@loadable/component";
import React, { useEffect, useState } from "react";
import { hydrate } from "react-dom";
import { I18nextProvider, useSSR } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { defaultLang, getLanguageFromPath, getPathForLanguage, i18n, } from "@utils/i18n";
import { getApolloClient } from "@client";
import BaseApp from "./BaseApp";
const App = () => {
    useSSR(window.initialI18nStore, window.initialLanguage);
    const [languageFromPath, setLanguageFromPath] = useState(getLanguageFromPath(window.location.pathname));
    useEffect(() => {
        i18n.on("languageChanged", () => {
            const languageFrom = getLanguageFromPath(window.location.pathname);
            if (i18n.language !== languageFrom) {
                setLanguageFromPath(i18n.language);
                history.replaceState(null, "", getPathForLanguage(location.pathname, i18n.language));
            }
        });
    }, []);
    return (React.createElement(I18nextProvider, { i18n: i18n },
        React.createElement(ApolloProvider, { client: getApolloClient() },
            React.createElement(BrowserRouter, { key: languageFromPath, basename: defaultLang !== languageFromPath
                    ? `/${languageFromPath}`
                    : undefined },
                React.createElement(BaseApp, null)))));
};
loadableReady().then(() => {
    hydrate(React.createElement(App, null), document.getElementById("root"));
});
if (module.hot) {
    module.hot.accept();
}

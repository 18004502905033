import * as classNames from "classnames";
import * as React from "react";
import { LayoutContainer } from "@components/student/LayoutContainer";
import { emailAddress, facebookLink, instagramLink, phoneNumber, phoneNumberToCall, telegramLink, } from "@constants";
import { safeFbq } from "@utils/analytics";
import { useTranslation } from "react-i18next";
import * as s from "./Footer.sss";
export const Footer = ({ className }) => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: classNames(s.root, className) },
        React.createElement(LayoutContainer, { className: s.content },
            React.createElement("div", { className: s.iconLinks },
                React.createElement("p", null, t('student~Мы в социальных сетях')),
                React.createElement("div", { className: s.socialLinks },
                    React.createElement("a", { className: s.socialLink, href: instagramLink, target: "_blank", rel: "noreferrer" }, "Instagram"),
                    React.createElement("a", { className: s.socialLink, href: facebookLink, target: "_blank", rel: "noreferrer" }, "Facebook"),
                    React.createElement("a", { className: s.socialLink, href: telegramLink, target: "_blank", rel: "noreferrer" }, "Telegram"))),
            React.createElement("div", { className: s.contactsSection },
                React.createElement("p", null, t('student~Номер телефона')),
                React.createElement("a", { className: s.phone, href: phoneNumberToCall, onClick: () => safeFbq("track", "Contacts") }, phoneNumber)),
            React.createElement("div", { className: s.contactsSection },
                React.createElement("p", null, t('student~Почта')),
                emailAddress))));
};

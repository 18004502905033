import * as React from 'react';
import cx from 'classnames';
import { RadioInput } from '@components/student/RadioInput';
import s from './TextRadioGroup.sss';
export const TextRadioGroup = ({ name, value, onChange, options, error, className, groupClassName, }) => (React.createElement("div", { className: cx(s.root, className) },
    React.createElement("div", { className: groupClassName }, options.map(option => (React.createElement("div", { key: option.value, className: s.radioLabelGroup, onClick: () => onChange(option) },
        React.createElement(RadioInput, { name: name, checked: (value === null || value === void 0 ? void 0 : value.value.toString()) === option.value.toString() }),
        React.createElement("div", { className: s.radioLabel }, option.label))))),
    error &&
        React.createElement("div", { className: s.error }, error)));

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { CheckoutSummary, } from "@components/student/CheckoutSummary";
import { siteConcert, siteEvents } from "@urls";
import { individualScheduleVar, selectedEventVar, trialLessonVar, } from "@local-state/vars";
import { useCreateConcertPurchaseMutation, useCreateEventPurchaseMutation, useCreateLessonPurchaseOrderMutation, useCreateLessonPurchaseOrderWithoutScheduleMutation, } from "@graphql";
export const CheckoutSummaryContainer = props => {
    const { schedule, subscriptionId, promocodeId } = props;
    const history = useHistory();
    const [createLessonPurchaseOrder] = useCreateLessonPurchaseOrderMutation();
    const [createCreateEventPurchase] = useCreateEventPurchaseMutation();
    const [createConcertPurchase] = useCreateConcertPurchaseMutation();
    const [createLessonPurchaseOrderWithoutSchedule] = useCreateLessonPurchaseOrderWithoutScheduleMutation();
    const [paymentData, setPaymentData] = useState();
    const returnUrl = ``;
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            let wfpDataString;
            if ("lessonTemplatesIds" in schedule) {
                const { data } = yield createLessonPurchaseOrder({
                    variables: {
                        returnUrl,
                        lessonTemplatesIds: schedule.lessonTemplatesIds,
                        lessonSubscriptionId: subscriptionId,
                        promocodeId,
                    },
                });
                wfpDataString = data.createLessonPurchaseOrder.data;
            }
            else if ("eventId" in schedule) {
                try {
                    const { data } = yield createCreateEventPurchase({
                        variables: {
                            email: schedule.email,
                            eventId: schedule.eventId,
                            firstName: schedule.firstName,
                            lastName: schedule.lastName,
                            phoneNumber: schedule.phoneNumber,
                            returnUrl: schedule.returnUrl,
                            tgOrInst: schedule.tgOrInst,
                        },
                    });
                    wfpDataString = data.createEventPurchase.data;
                }
                catch (error) {
                    if (error.message.includes("Key (phone_number)=")) {
                        toast("Цей номер вже використаний!", { type: "error" });
                    }
                    history.push(siteEvents);
                }
            }
            else if ("concertId" in schedule) {
                try {
                    const { data } = yield createConcertPurchase({
                        variables: {
                            concertId: subscriptionId,
                            placeIds: schedule.placeIds,
                            email: schedule.email,
                            phoneNumber: schedule.phoneNumber,
                            expireTime: "00:10:00",
                            returnUrl: schedule.returnUrl,
                        },
                    });
                    wfpDataString = data.createConcertPurchase.data;
                }
                catch (error) {
                    if (error.message.includes("Key (phone_number)=")) {
                        toast("Цей номер вже використаний!", { type: "error" });
                    }
                    history.push(generatePath(siteConcert, {
                        concertId: subscriptionId,
                    }));
                }
            }
            else {
                createLessonPurchaseOrderWithoutSchedule({
                    variables: {
                        returnUrl,
                        lessonSubscriptionId: subscriptionId,
                        promocodeId,
                    },
                });
            }
            setPaymentData(JSON.parse(wfpDataString));
        }))();
    }, [schedule, subscriptionId]);
    useEffect(() => {
        return () => {
            individualScheduleVar([]);
            trialLessonVar([]);
            selectedEventVar(undefined);
        };
    }, []);
    return React.createElement(CheckoutSummary, Object.assign({}, props, { paymentData: paymentData }));
};

import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@components/student/Button";
import { Modal } from "@components/student/Modal";
import CloseIcon from "@static/svg/close.svg";
import classNames from "classnames";
import * as s from "./LevelNotification.sss";
export const LevelNotification = ({ agreeLevel, cancelAddition, title, warningDescription, citiesInput, }) => {
    var _a, _b;
    const { t } = useTranslation();
    const [isOpenSelect, setIsOpenSelect] = React.useState(false);
    const [selectedCity, setSelectedCity] = React.useState({ id: (_a = citiesInput[0]) === null || _a === void 0 ? void 0 : _a.id, title: (_b = citiesInput[0]) === null || _b === void 0 ? void 0 : _b.title });
    React.useEffect(() => {
        citiesInput
            ? localStorage.setItem("cityModal", true.toString())
            : localStorage.removeItem("cityModal");
        localStorage.setItem("cityId", selectedCity.id.toString());
        localStorage.setItem("cityName", selectedCity.title);
    }, [selectedCity, citiesInput]);
    const toggleSelect = () => {
        setIsOpenSelect(prevState => !prevState);
    };
    const handleChangeCity = (cityId) => {
        const currentLocation = citiesInput.find(({ id }) => id === cityId);
        setSelectedCity(currentLocation);
    };
    const handleAgree = () => {
        agreeLevel(true);
        window.location.reload();
    };
    const handleDeny = () => {
        !warningDescription && agreeLevel(false);
        cancelAddition();
    };
    return (React.createElement(Modal, { className: classNames(s.root, { [s.inputCity]: citiesInput }), theme: "white", isOpen: true },
        React.createElement("div", { className: s.close },
            React.createElement(CloseIcon, { className: s.closeIcon, onClick: agreeLevel })),
        React.createElement("div", { className: s.levelNotification },
            React.createElement("div", { className: s.content },
                title && React.createElement("h2", { className: s.titleHeader }, title),
                React.createElement("h3", { className: s.titleDescription }, warningDescription ||
                    (!citiesInput &&
                        t("student~Ви впевнені? Новачкам рекомендується вибирати рівень не вище Start+"))),
                citiesInput && (React.createElement("div", { className: s.cityInput },
                    React.createElement("label", { htmlFor: "city", id: "cityLabel", className: s.cityLabel }, "\u041C\u0456\u0441\u0442\u043E"),
                    React.createElement("div", { className: s.selectWrapper, onClick: toggleSelect },
                        React.createElement("input", { type: "text", id: "city", className: s.citySelect, value: selectedCity === null || selectedCity === void 0 ? void 0 : selectedCity.title }),
                        React.createElement("ul", { className: `${s.cityList} ${isOpenSelect ? s.open : s.closed}` }, citiesInput.map((city) => (React.createElement("li", { key: city.id, onClick: () => handleChangeCity(city.id) }, city.title)))))))),
            React.createElement("div", { className: s.levelNotificationButtons },
                React.createElement("div", { className: citiesInput && s.cityChoiceContainer },
                    React.createElement(Button, { onClick: handleAgree, theme: citiesInput ? "primaryOrange" : "secondaryOrange", className: s.agreeButton + (citiesInput && ` ${s.cityChoice}`), id: "levelNotification" }, !citiesInput
                        ? t("student~Так, підтверджую")
                        : t("student~Вибрати"))),
                !citiesInput && (React.createElement("div", null,
                    React.createElement(Button, { onClick: handleDeny, theme: "primaryPurple", className: s.negativeButton, id: "levelNotification" }, t("student~Ні, вибрати інший рівень"))))))));
};

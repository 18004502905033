import * as classNames from "classnames";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@components/student/Button";
import { LayoutContainer } from "@components/student/LayoutContainer";
import { NavItem } from "@components/student/NavBar/NavItem";
import { getPersonalCabinet, getStudentsNavigation, } from "@components/student/NavBar/constants";
import { Link } from "@components/ui/Link";
import { addClassPhoneAnalitic, safeFbq } from "@utils/analytics";
import { siteIndex, trialLessonModalHash } from "@urls";
import FacebookIcon from "@svg/facebookFilled.svg";
import InstagramIcon from "@svg/instagram.svg";
import MaryBalletTopIcon from "@svg/maryBalletRedesignLogo.svg";
import TelegramIcon from "@svg/telegramFilled.svg";
import TiktokIcon from "@svg/tiktok-icon.svg";
import * as s from "./Desktop.sss";
const themeClassName = {
    primary: s.themePrimary,
    "primary-transparent": s.themePrimaryTransparent,
    "bright-transparent": s.themeBrightTransparent,
    bright: s.themeBright,
    purple: s.themePurple,
};
const positionClassName = {
    fixed: s.positionFixed,
    static: s.positionStatic,
};
export const Desktop = ({ className, theme, cities, position, isLoggedIn, logout, }) => {
    const { t } = useTranslation();
    const [activeItem, setActiveItem] = useState("");
    const studentsNavigation = getStudentsNavigation(t);
    const personalCabinet = getPersonalCabinet(t);
    const cityId = localStorage.getItem("cityId");
    const currentCity = localStorage.getItem("cityName");
    const currentLocation = cities.find(({ id }) => id.toString() === cityId);
    const navItemClick = (e, subTitle) => {
        activeItem !== e ? setActiveItem(e) : setActiveItem("");
        if (t("login~Вийти") === subTitle) {
            logout();
        }
    };
    const selectNewCity = () => {
        localStorage.removeItem("cityChoised");
        window.location.reload();
    };
    React.useEffect(() => {
        if (isLoggedIn &&
            !localStorage.getItem("isChecked") &&
            !sessionStorage.getItem("isNotChecked")) {
            logout();
        }
    });
    return (React.createElement(React.Fragment, null,
        position === "fixed" && (React.createElement("div", { className: className, style: { height: "120px" } })),
        React.createElement("div", { className: classNames(s.root, positionClassName[position], themeClassName[theme], className) },
            React.createElement(LayoutContainer, null,
                React.createElement("div", { className: s.topMenu },
                    React.createElement("div", null,
                        React.createElement("div", { className: s.socials },
                            React.createElement("a", { target: "_blank", href: currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.instagram, className: s.social, rel: "noreferrer" },
                                React.createElement(InstagramIcon, null)),
                            React.createElement("a", { target: "_blank", href: currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.telegram, className: s.social, rel: "noreferrer" },
                                React.createElement(TelegramIcon, null)),
                            React.createElement("a", { target: "_blank", href: currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.facebook, className: s.social, rel: "noreferrer" },
                                React.createElement(FacebookIcon, null)),
                            React.createElement("a", { target: "_blank", href: currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.tiktok, className: s.social, rel: "noreferrer" },
                                React.createElement(TiktokIcon, null)),
                            React.createElement("span", { className: s.currentCity, onClick: selectNewCity }, currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.title))),
                    React.createElement(Link, { to: siteIndex, className: s.logo },
                        React.createElement(MaryBalletTopIcon, null)),
                    React.createElement("div", { className: s.contactUs },
                        React.createElement("a", { className: classNames(s.phone, addClassPhoneAnalitic(currentCity)), href: `tel:+${currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.phoneNumber}`, onClick: () => safeFbq("track", "Contacts"), "data-location": currentCity }, currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.phoneNumber),
                        React.createElement("div", null),
                        React.createElement(Link, { theme: "clean", to: trialLessonModalHash },
                            React.createElement(Button, { className: s.bottomLink, theme: "darkBlueOutline" }, t("student~Записатися"))))),
                React.createElement("nav", { className: s.nav }, studentsNavigation.map(navItem => (React.createElement(NavItem, { key: navItem.title, navItem: navItem, theme: theme, isActive: "subNav" in navItem && navItem.title === activeItem, onClick: "subNav" in navItem
                        ? e => {
                            activeItem !== e ? setActiveItem(e) : setActiveItem("");
                        }
                        : undefined }))))))));
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useHistory } from "react-router-dom";
import { Button } from "@components/student/Button";
import { SubscriptionCard, } from "@components/student/SubscriptionCard";
import { usePromoCodeMutation } from "@graphql";
import { PromoCodeInput } from "../PromoCodeInput";
import * as s from "./CheckoutSubscriptionInfo.sss";
import { ScheduleType } from "@/constants";
import { studentScheduleSelectSubscription, } from "@/urls";
export const CheckoutSubscriptionInfo = (_a) => {
    var { className, setPromoCode } = _a, props = __rest(_a, ["className", "setPromoCode"]);
    const { t } = useTranslation();
    const [promoCode] = usePromoCodeMutation();
    const history = useHistory();
    const changeSubscription = () => {
        history.push(generatePath(studentScheduleSelectSubscription, {
            scheduleType: ScheduleType.Individual,
        }));
    };
    const promoCodeClick = (couponCode) => __awaiter(void 0, void 0, void 0, function* () {
        let response = null;
        if (!couponCode) {
            setPromoCode(null);
        }
        else {
            response = yield promoCode({
                variables: {
                    subscriptionId: props.subscription.id,
                    text: couponCode,
                },
            });
            if (response.data) {
                setPromoCode(response.data.activatePromocode);
            }
        }
        return response;
    });
    return (React.createElement("div", { className: classNames(s.root, className) },
        React.createElement("div", { className: s.title }, t("student~Абонемент")),
        React.createElement(SubscriptionCard, Object.assign({}, props)),
        React.createElement(Button, { className: s.changeSubscriptionButton, theme: "secondaryPurple", onClick: changeSubscription }, t("student~Выбрать другой абонемент")),
        React.createElement(PromoCodeInput, { onSubmit: promoCodeClick })));
};

import { useApolloClient } from '@apollo/client';
import { individualScheduleVar, trialLessonVar, selectedSubscriptionVar, selectedEventVar, selectedConcertVar } from '@local-state/vars';
import { IndividualScheduleDataFragmentDoc, LessonSubscriptionDataFragmentDoc, } from '@graphql';
const lessonTemplateObjectTypeName = 'LessonTemplate';
const subscriptionObjectTypeName = 'LessonSubscription';
const useStudentCheckoutData = () => {
    const client = useApolloClient();
    const selectedIndividualTemplatesIds = individualScheduleVar();
    ;
    let selectedIndividualTemplates = selectedIndividualTemplatesIds.map(lessonTemplateId => client.readFragment({
        id: `${lessonTemplateObjectTypeName}:${lessonTemplateId}`,
        fragment: IndividualScheduleDataFragmentDoc
    }));
    if (selectedIndividualTemplatesIds.includes(null)) {
        console.error('Missed lesson in cache. ' +
            `Selected templates: ${selectedIndividualTemplatesIds}. ` +
            `Found lessons in cache: ${selectedIndividualTemplates}.`);
        selectedIndividualTemplates = [];
    }
    const selectedTrialLessonsIds = trialLessonVar();
    let selectedTrialLessons = selectedTrialLessonsIds.map(lessonTemplateId => (client.readFragment({
        id: `${lessonTemplateObjectTypeName}:${lessonTemplateId}`,
        fragment: IndividualScheduleDataFragmentDoc
    }))).filter(Boolean);
    const selectedSubscriptionId = selectedSubscriptionVar();
    const selectedSubscription = client.readFragment({
        id: `${subscriptionObjectTypeName}:${selectedSubscriptionId}`,
        fragment: LessonSubscriptionDataFragmentDoc,
    });
    const selectedEvent = selectedEventVar();
    const selectedConcert = selectedConcertVar();
    return {
        lessonTemplates: selectedIndividualTemplates,
        subscription: selectedSubscription,
        trialLesson: selectedTrialLessons,
        selectedEvent,
        selectedConcert
    };
};
export default useStudentCheckoutData;

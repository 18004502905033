var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import dayjs from "dayjs";
import { FORM_ERROR } from "final-form";
import * as React from "react";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
export const isPhoneNumberValid = (phoneNumber) => {
    const normalizedPhoneNumber = phoneNumber.replace(/\s/g, "");
    if (normalizedPhoneNumber.length) {
        const isNumber = Number(normalizedPhoneNumber);
        return (isNumber &&
            ((normalizedPhoneNumber.startsWith("+380") &&
                normalizedPhoneNumber.length === 13) ||
                (normalizedPhoneNumber.startsWith("380") &&
                    normalizedPhoneNumber.length === 12) ||
                (normalizedPhoneNumber.startsWith("80") &&
                    normalizedPhoneNumber.length === 11) ||
                (normalizedPhoneNumber.startsWith("0") &&
                    normalizedPhoneNumber.length === 10)));
    }
    return false;
};
export const getRandomInt = (start, end) => Math.floor(Math.random() * (end - start) + start);
export const normalizePhoneNumber = (phoneNumber) => {
    const numberWithoutSpaces = phoneNumber.replace(/\s/g, "");
    if (numberWithoutSpaces.startsWith("+") &&
        isPhoneNumberValid(numberWithoutSpaces)) {
        return numberWithoutSpaces.slice(1);
    }
    if (!isNaN(Number(numberWithoutSpaces))) {
        const length = numberWithoutSpaces.length;
        if (numberWithoutSpaces.startsWith("380") && length === 12) {
            return numberWithoutSpaces;
        }
        if (numberWithoutSpaces.startsWith("80") && length === 11) {
            return `3${numberWithoutSpaces}`;
        }
        if (numberWithoutSpaces.startsWith("0") && length === 10) {
            return `38${numberWithoutSpaces}`;
        }
    }
    throw new Error(`${phoneNumber} - is not a valid phone number.`);
};
export const formatTime = (time) => {
    let hour = `${time.getHours()}`;
    let minute = `${time.getMinutes()}`;
    if (hour.length === 1) {
        hour = `0${hour}`;
    }
    if (minute.length === 1) {
        minute = `0${minute}`;
    }
    return `${hour}:${minute}`;
};
export const useScrollBottomEffect = ({ callback, ref, rangeToBottom, }) => {
    useLayoutEffect(() => {
        const windowScrollHandler = () => __awaiter(void 0, void 0, void 0, function* () {
            const windowScrollElement = document.scrollingElement || document.documentElement;
            const windowScrollOffset = window.innerHeight + windowScrollElement.scrollTop;
            const componentBottomScrollOffset = ref.current.offsetTop + ref.current.clientHeight;
            if (windowScrollOffset > componentBottomScrollOffset - rangeToBottom) {
                yield callback();
            }
        });
        window.addEventListener("scroll", windowScrollHandler, { passive: true });
        return () => window.removeEventListener("scroll", windowScrollHandler);
    }, [callback, rangeToBottom, ref]);
};
export const useSearchParams = () => {
    const location = useLocation();
    const getSearchParams = useCallback(() => new URLSearchParams(location.search), [location.search]);
    const [searchParams, setSearchParams] = useState(getSearchParams);
    useEffect(() => setSearchParams(getSearchParams), [location, getSearchParams]);
    return searchParams;
};
export const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
export const googleMapsUrl = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAGvadG6rYjdr86rF_1j7sVEZXXlORMdto";
export const initMapFunctionName = "initMapFunction";
export const initMapDataKey = "initMapData";
export function loadGoogleMaps() {
    return new Promise(resolve => {
        var _a;
        if (!((_a = window[initMapDataKey]) === null || _a === void 0 ? void 0 : _a.state)) {
            window[initMapDataKey] = {
                state: "loading",
                queue: [resolve],
            };
            const scriptElement = document.createElement("script");
            scriptElement.type = "text/javascript";
            scriptElement.src = `${googleMapsUrl}&callback=${initMapFunctionName}`;
            window[initMapFunctionName] = () => {
                window[initMapDataKey].state = "loaded";
                window[initMapDataKey].queue.forEach(callback => callback());
                window[initMapDataKey].queue = undefined;
            };
            document.body.appendChild(scriptElement);
        }
        else if (window[initMapDataKey].state === "loading") {
            window[initMapDataKey].queue.push(resolve);
        }
        else if (window[initMapDataKey].state === "loaded") {
            resolve();
        }
        else {
            throw new Error(`Unknown state ${this.state}`);
        }
    });
}
export const useIsClient = () => {
    const [isClient, setIsClient] = useState(false);
    useEffect(() => setIsClient(true), []);
    return isClient;
};
export const getFieldsSingleError = (errors) => errors.reduce((fieldsSingleError, { field, errors: fieldErrors }) => {
    if (field === "__all__") {
        fieldsSingleError[FORM_ERROR] = fieldErrors[0].message;
        return fieldsSingleError;
    }
    let currentLevel = fieldsSingleError;
    const levels = field.split(".");
    levels.forEach((level, index) => {
        if (index === levels.length - 1) {
            currentLevel[level] = fieldErrors[0].message;
        }
        else if (currentLevel[level]) {
            currentLevel = currentLevel[level];
        }
        else {
            currentLevel[level] = {};
            currentLevel = currentLevel[level];
        }
    });
    return fieldsSingleError;
}, {});
export const paymentDataToFormInputs = (paymentData) => {
    const inputs = [];
    for (const [field, value] of Object.entries(paymentData)) {
        if (Array.isArray(value)) {
            for (const arrayValue of value) {
                inputs.push(React.createElement("input", { type: "hidden", key: `${field} ${arrayValue}`, name: field, value: arrayValue }));
            }
        }
        else {
            inputs.push(React.createElement("input", { type: "hidden", key: `${field}`, name: field, value: value }));
        }
    }
    return inputs;
};
export const getTimeCorrected = (time) => {
    const formattedTime = dayjs(time, "HH:mm", true);
    if (formattedTime.isValid()) {
        return formattedTime.format("HH:mm");
    }
    return time.slice(0, 5);
};
export function extractUniqueColorsAndPrices(dataConcert) {
    const uniqueCombinations = {};
    const result = [];
    dataConcert.concertRows.forEach(row => {
        row.rowPlaces.forEach(place => {
            const { color, price } = place;
            const key = `${color.toLowerCase()}-${price}`;
            if (!uniqueCombinations[key]) {
                uniqueCombinations[key] = true;
                result.push({ color, price });
            }
        });
    });
    return result;
}
export function transliterateCity(city) {
    switch (city) {
        case "Київ":
            return "Киев";
        case "Львів":
            return "Львов";
        default:
            return city;
    }
}
;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as classNames from "classnames";
import * as React from "react";
import { Input } from "@components/ui/Input";
import * as s from "./LabelInput.sss";
export const LabelInput = (_a) => {
    var { label, error, className, type } = _a, inputProps = __rest(_a, ["label", "error", "className", "type"]);
    return (React.createElement("label", { className: classNames(s.root, className) },
        label && React.createElement("div", { className: s.labelText }, label),
        React.createElement(Input, Object.assign({}, inputProps, { invalid: Array.isArray(error) ? Boolean(error.length) : Boolean(error), type: type })),
        error &&
            (typeof error === "string" ? (React.createElement("div", { className: s.error }, error)) : (error.map((singleError, i) => (React.createElement("div", { key: i, className: s.error }, singleError)))))));
};

const sendGAEvent = ({ eventCategory, eventAction, eventLabel, eventValue, }) => {
    try {
        ga("send", "event", eventCategory, eventAction, eventLabel, eventValue);
    }
    catch (e) {
        console.log(eventCategory, eventAction, eventLabel, eventValue, e);
    }
};
export const sendTrialLessonBannerEvent = (bannerTiming) => sendGAEvent({
    eventCategory: "Trial Lesson Subscription",
    eventAction: "Sent Phone Number",
    eventLabel: "Sent from banner",
    eventValue: bannerTiming,
});
export const safeFbq = (...args) => {
    try {
        fbq(...args);
    }
    catch (e) {
        console.error(args, e);
    }
};
export const addClassLocationAnalitic = (currentCity) => {
    switch (currentCity) {
        case "Львів":
            return `location_lviv`;
        case "Київ":
            return `location_kiev`;
        default:
            return `location_default`;
    }
};
export const addClassPhoneAnalitic = (currentCity) => {
    switch (currentCity) {
        case "Львів":
            return `tel_location_lviv`;
        case "Київ":
            return `tel_location_kiev`;
        default:
            return `location_default`;
    }
};

import * as React from 'react';
import fetch from 'cross-fetch';
import { ApolloClient, ApolloLink, InMemoryCache, ApolloProvider, } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
export const getApolloClient = arg => new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from([
        createUploadLink({
            fetch: typeof window === 'undefined' ? fetch : undefined,
            uri: typeof window === 'undefined'
                ? process.env.GRAPH_ENDPOINT
                : '/api/graphql/',
            credentials: 'same-origin',
            headers: arg === null || arg === void 0 ? void 0 : arg.headers,
        })
    ]),
    cache: new InMemoryCache().restore(typeof window === 'undefined'
        ? {}
        : window.__APOLLO_STATE__)
});
export const withApolloHooksProvider = (Component) => (function (props) {
    return React.createElement(ApolloProvider, { client: getApolloClient() },
        React.createElement(Component, Object.assign({}, props)));
});

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import * as ReactModal from 'react-modal';
import * as cx from 'classnames';
import Close from '@svg/close.svg';
import * as s from './Modal.sss';
export const Modal = (_a) => {
    var { withClose = false, onRequestClose, className, overlayClassName, portalClassName, isOpen, children } = _a, props = __rest(_a, ["withClose", "onRequestClose", "className", "overlayClassName", "portalClassName", "isOpen", "children"]);
    return (React.createElement(ReactModal, Object.assign({ className: cx(s.root, className), isOpen: isOpen, onRequestClose: onRequestClose, closeTimeoutMS: 300, overlayClassName: cx(s.overlay, overlayClassName), portalClassName: cx(s.portal, { [s.hidden]: !isOpen }, portalClassName) }, props),
        withClose &&
            React.createElement("div", { className: s.close, onClick: onRequestClose },
                React.createElement(Close, { className: s.closeIcon })),
        children));
};

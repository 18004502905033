export const getLessonDeclension = (t, n) => {
    const lastDigit = n % 10;
    switch (lastDigit) {
        case 1:
            return t('заняття');
        case 2:
            return t('заняття');
        case 3:
            return t('заняття');
        case 4:
            return t('заняття');
        default:
            return t('занять');
    }
};

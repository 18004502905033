import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export var CoachGradationEnum;
(function (CoachGradationEnum) {
    CoachGradationEnum["Intern"] = "intern";
    CoachGradationEnum["Coach"] = "coach";
    CoachGradationEnum["MiddleCoach"] = "middle_coach";
    CoachGradationEnum["SeniorCoach"] = "senior_coach";
    CoachGradationEnum["LeadCoach"] = "lead_coach";
})(CoachGradationEnum || (CoachGradationEnum = {}));
export var LessonTime;
(function (LessonTime) {
    LessonTime["Morning"] = "MORNING";
    LessonTime["Evening"] = "EVENING";
    LessonTime["AllDay"] = "ALL_DAY";
})(LessonTime || (LessonTime = {}));
export var PromocodeType;
(function (PromocodeType) {
    PromocodeType["Single"] = "SINGLE";
    PromocodeType["Multiply"] = "MULTIPLY";
})(PromocodeType || (PromocodeType = {}));
export var SocialAuthProviderEnum;
(function (SocialAuthProviderEnum) {
    SocialAuthProviderEnum["Facebook"] = "FACEBOOK";
    SocialAuthProviderEnum["Google"] = "GOOGLE";
})(SocialAuthProviderEnum || (SocialAuthProviderEnum = {}));
export const RegisterResultInfoFragmentDoc = gql `
    fragment RegisterResultInfo on RegisterUser {
  user {
    id
    isCoach
  }
  errors {
    field
    errors {
      code
      message
    }
  }
}
    `;
export const IndividualScheduleDataFragmentDoc = gql `
    fragment IndividualScheduleData on LessonTemplate {
  id
  hall {
    id
    name
    city
    street
    lat
    lng
    locations {
      id
      name
      color
      isAvailable
    }
  }
  weekday
  time
  type {
    id
    name
  }
  skillLevel {
    id
    name
  }
  duration
  freePlaceCount
  coach {
    id
    firstName
    lastName
  }
}
    `;
export const ErrorsDataFragmentDoc = gql `
    fragment errorsData on Error {
  field
  errors {
    code
    message
  }
}
    `;
export const LessonDetailVisitAnalyticsFragmentDoc = gql `
    fragment lessonDetailVisitAnalytics on Lesson {
  visitAnalytics {
    inAll
    shouldPresent
    notPaid
    trialLesson
    willAbsent
  }
}
    `;
export const LessonSubscriptionDataFragmentDoc = gql `
    fragment LessonSubscriptionData on LessonSubscription {
  id
  name
  description
  lessonsCount
  movesCount
  freezeDaysCount
  price
  available
}
    `;
export const HallDetailsFragmentDoc = gql `
    fragment HallDetails on Hall {
  id
  name
  city
  street
}
    `;
export const LessonTypesDocument = gql `
    query LessonTypes {
  lessonType {
    id
    name
  }
}
    `;
export function useLessonTypesQuery(baseOptions) {
    return Apollo.useQuery(LessonTypesDocument, baseOptions);
}
export function useLessonTypesLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(LessonTypesDocument, baseOptions);
}
export const ScheduleDocument = gql `
    query Schedule {
  lessonTemplates {
    id
    freePlaceCount
    hall {
      id
      name
      street
      lat
      lng
      city
      locations {
        id
        name
        color
        priority
        isAvailable
      }
    }
    weekday
    time
    type {
      id
      name
    }
    skillLevel {
      id
      name
    }
  }
}
    `;
export function useScheduleQuery(baseOptions) {
    return Apollo.useQuery(ScheduleDocument, baseOptions);
}
export function useScheduleLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(ScheduleDocument, baseOptions);
}
export const CitiesDocument = gql `
    query Cities {
  cities {
    facebook
    id
    instagram
    phoneNumber
    telegram
    tiktok
    title
  }
}
    `;
export function useCitiesQuery(baseOptions) {
    return Apollo.useQuery(CitiesDocument, baseOptions);
}
export function useCitiesLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(CitiesDocument, baseOptions);
}
export const NewsPageFilterDocument = gql `
    query NewsPageFilter {
  newsCategories {
    edges {
      node {
        id
        slug
        name
      }
    }
  }
}
    `;
export function useNewsPageFilterQuery(baseOptions) {
    return Apollo.useQuery(NewsPageFilterDocument, baseOptions);
}
export function useNewsPageFilterLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(NewsPageFilterDocument, baseOptions);
}
export const LessonLevelDocument = gql `
    query LessonLevel {
  lessonLevel {
    id
    name
  }
}
    `;
export function useLessonLevelQuery(baseOptions) {
    return Apollo.useQuery(LessonLevelDocument, baseOptions);
}
export function useLessonLevelLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(LessonLevelDocument, baseOptions);
}
export const CreateTrialLessonRecordDocument = gql `
    mutation CreateTrialLessonRecord($phoneNumber: String!, $lastName: String!, $firstName: String!, $instagram: String, $city: String) {
  createTrialLessonRecord(
    firstName: $firstName
    lastName: $lastName
    phoneNumber: $phoneNumber
    instagram: $instagram
    city: $city
  ) {
    isSuccess
  }
}
    `;
export function useCreateTrialLessonRecordMutation(baseOptions) {
    return Apollo.useMutation(CreateTrialLessonRecordDocument, baseOptions);
}
export const PromoCodeDocument = gql `
    mutation PromoCode($subscriptionId: Int!, $text: String!) {
  activatePromocode(subscriptionId: $subscriptionId, text: $text) {
    type
    text
    procents
    id
    activeToDate
    activeFromDate
    subscription {
      available
      description
      freezeDaysCount
      id
      lessonsCount
      movesCount
      name
      price
      isFlexible
    }
  }
}
    `;
export function usePromoCodeMutation(baseOptions) {
    return Apollo.useMutation(PromoCodeDocument, baseOptions);
}
export const LoginDocument = gql `
    mutation Login($login: String!, $password: String!) {
  authenticate(login: $login, password: $password) {
    user {
      id
      isCoach
    }
    errors {
      field
      errors {
        code
        message
      }
    }
  }
}
    `;
export function useLoginMutation(baseOptions) {
    return Apollo.useMutation(LoginDocument, baseOptions);
}
export const RegisterDocument = gql `
    mutation Register($firstName: String!, $lastName: String!, $phoneNumber: String!, $password: String!) {
  registerUser(
    firstName: $firstName
    lastName: $lastName
    phoneNumber: $phoneNumber
    password: $password
  ) {
    ...RegisterResultInfo
  }
}
    ${RegisterResultInfoFragmentDoc}`;
export function useRegisterMutation(baseOptions) {
    return Apollo.useMutation(RegisterDocument, baseOptions);
}
export const RegisterSocialDocument = gql `
    mutation RegisterSocial($accessToken: String!, $socialType: SocialAuthProviderEnum!, $phoneNumber: String!, $password: String!, $firstName: String!, $lastName: String!) {
  registerUser(
    accessToken: $accessToken
    socialType: $socialType
    phoneNumber: $phoneNumber
    password: $password
    firstName: $firstName
    lastName: $lastName
  ) {
    ...RegisterResultInfo
  }
}
    ${RegisterResultInfoFragmentDoc}`;
export function useRegisterSocialMutation(baseOptions) {
    return Apollo.useMutation(RegisterSocialDocument, baseOptions);
}
export const SendPasswordSmsDocument = gql `
    mutation SendPasswordSMS($phoneNumber: String!) {
  sendPhoneVerificationCode(phoneNumber: $phoneNumber) {
    errors {
      field
      errors {
        code
        message
      }
    }
  }
}
    `;
export function useSendPasswordSmsMutation(baseOptions) {
    return Apollo.useMutation(SendPasswordSmsDocument, baseOptions);
}
export const SendVerificationCodeDocument = gql `
    mutation SendVerificationCode($phoneNumber: String!) {
  sendPhoneVerificationCode(phoneNumber: $phoneNumber) {
    errors {
      field
      errors {
        code
        message
      }
    }
  }
}
    `;
export function useSendVerificationCodeMutation(baseOptions) {
    return Apollo.useMutation(SendVerificationCodeDocument, baseOptions);
}
export const VerifyCodeDocument = gql `
    mutation VerifyCode($phoneNumber: String!, $smsPassword: String!) {
  verifyNumber(smsPassword: $smsPassword, phoneNumber: $phoneNumber) {
    isSuccess
    errors {
      errors {
        code
        message
      }
      field
    }
  }
}
    `;
export function useVerifyCodeMutation(baseOptions) {
    return Apollo.useMutation(VerifyCodeDocument, baseOptions);
}
export const ResetPasswordDocument = gql `
    mutation ResetPassword($phoneNumber: String!, $verificationCode: String!, $password: String!) {
  resetPassword(
    phoneNumber: $phoneNumber
    verificationCode: $verificationCode
    password: $password
  ) {
    errors {
      field
      errors {
        code
        message
      }
    }
  }
}
    `;
export function useResetPasswordMutation(baseOptions) {
    return Apollo.useMutation(ResetPasswordDocument, baseOptions);
}
export const NewsArticlePageDocument = gql `
    query NewsArticlePage($id: Int!) {
  article(id: $id) {
    id
    slug
    title
    image
    content
    author
    createdAt
    tags {
      id
      slug
      name
    }
    similarArticles {
      id
      slug
      title
      thumbnail
      createdAt
      category {
        slug
        id
      }
    }
    meta {
      title
      description
    }
  }
}
    `;
export function useNewsArticlePageQuery(baseOptions) {
    return Apollo.useQuery(NewsArticlePageDocument, baseOptions);
}
export function useNewsArticlePageLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(NewsArticlePageDocument, baseOptions);
}
export const NewsCategoryPageDocument = gql `
    query NewsCategoryPage($slug: String!) {
  newsCategory(slug: $slug) {
    id
    meta {
      title
      description
    }
    slug
    name
    description
  }
}
    `;
export function useNewsCategoryPageQuery(baseOptions) {
    return Apollo.useQuery(NewsCategoryPageDocument, baseOptions);
}
export function useNewsCategoryPageLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(NewsCategoryPageDocument, baseOptions);
}
export const NewsCategoryPageArticlesDocument = gql `
    query NewsCategoryPageArticles($slug: String!, $first: Int, $after: String) {
  articles(categorySlug: $slug, first: $first, after: $after) {
    edges {
      node {
        id
        slug
        title
        thumbnail
        createdAt
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;
export function useNewsCategoryPageArticlesQuery(baseOptions) {
    return Apollo.useQuery(NewsCategoryPageArticlesDocument, baseOptions);
}
export function useNewsCategoryPageArticlesLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(NewsCategoryPageArticlesDocument, baseOptions);
}
export const NewsPageDocument = gql `
    query NewsPage {
  newsCategories(first: 20) {
    edges {
      node {
        id
        slug
        name
        lastArticles {
          id
          slug
          thumbnail
          createdAt
          title
        }
      }
    }
  }
}
    `;
export function useNewsPageQuery(baseOptions) {
    return Apollo.useQuery(NewsPageDocument, baseOptions);
}
export function useNewsPageLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(NewsPageDocument, baseOptions);
}
export const SendResumeDocument = gql `
    mutation SendResume($firstName: String!, $email: String!, $phoneNumber: String!, $resume: Upload!) {
  sendResume(
    firstName: $firstName
    email: $email
    phoneNumber: $phoneNumber
    resume: $resume
  ) {
    errors {
      field
      errors {
        message
        code
      }
    }
  }
}
    `;
export function useSendResumeMutation(baseOptions) {
    return Apollo.useMutation(SendResumeDocument, baseOptions);
}
export const ConcertRowsDocument = gql `
    query ConcertRows($concertId: Int!) {
  concertRows(concertId: $concertId) {
    hall {
      city
      id
      name
      street
    }
    id
    name
    rowPlaces {
      color
      id
      isAvailable
      name
      price
    }
  }
}
    `;
export function useConcertRowsQuery(baseOptions) {
    return Apollo.useQuery(ConcertRowsDocument, baseOptions);
}
export function useConcertRowsLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(ConcertRowsDocument, baseOptions);
}
export const HallDetailsDocument = gql `
    query HallDetails {
  halls {
    ...HallDetails
    aboutStudio
    locationText
    locations {
      id
      name
      color
      isAvailable
    }
  }
}
    ${HallDetailsFragmentDoc}`;
export function useHallDetailsQuery(baseOptions) {
    return Apollo.useQuery(HallDetailsDocument, baseOptions);
}
export function useHallDetailsLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(HallDetailsDocument, baseOptions);
}
export const HallsListDocument = gql `
    query HallsList {
  halls {
    ...HallDetails
  }
}
    ${HallDetailsFragmentDoc}`;
export function useHallsListQuery(baseOptions) {
    return Apollo.useQuery(HallsListDocument, baseOptions);
}
export function useHallsListLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(HallsListDocument, baseOptions);
}
export const HallsMapDocument = gql `
    query HallsMap {
  halls {
    id
    street
    lat
    lng
    images
  }
}
    `;
export function useHallsMapQuery(baseOptions) {
    return Apollo.useQuery(HallsMapDocument, baseOptions);
}
export function useHallsMapLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(HallsMapDocument, baseOptions);
}
export const OfflineSubscriptionsListDocument = gql `
    query OfflineSubscriptionsList {
  balletWithLock: lessonSubscriptions(forPointe: false, isFlexible: false) {
    ...LessonSubscriptionData
  }
  ballet: lessonSubscriptions(forPointe: false, isFlexible: true) {
    ...LessonSubscriptionData
  }
  pointe: lessonSubscriptions(forPointe: true, isFlexible: false) {
    ...LessonSubscriptionData
  }
}
    ${LessonSubscriptionDataFragmentDoc}`;
export function useOfflineSubscriptionsListQuery(baseOptions) {
    return Apollo.useQuery(OfflineSubscriptionsListDocument, baseOptions);
}
export function useOfflineSubscriptionsListLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(OfflineSubscriptionsListDocument, baseOptions);
}
export const ChangePasswordFormDocument = gql `
    mutation ChangePasswordForm($newPassword: String!) {
  changePassword(newPassword: $newPassword) {
    errors {
      ...errorsData
    }
  }
}
    ${ErrorsDataFragmentDoc}`;
export function useChangePasswordFormMutation(baseOptions) {
    return Apollo.useMutation(ChangePasswordFormDocument, baseOptions);
}
export const CheckoutStartDateInputInfoDocument = gql `
    query CheckoutStartDateInputInfo($lessonTemplatesIds: [Int!]!) {
  subscriptionStartDates(lessonTemplatesIds: $lessonTemplatesIds)
}
    `;
export function useCheckoutStartDateInputInfoQuery(baseOptions) {
    return Apollo.useQuery(CheckoutStartDateInputInfoDocument, baseOptions);
}
export function useCheckoutStartDateInputInfoLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(CheckoutStartDateInputInfoDocument, baseOptions);
}
export const CreateLessonPurchaseOrderDocument = gql `
    mutation CreateLessonPurchaseOrder($lessonTemplatesIds: [Int!]!, $lessonSubscriptionId: Int!, $returnUrl: String!, $promocodeId: Int) {
  createLessonPurchaseOrder(
    lessonTemplatesIds: $lessonTemplatesIds
    lessonSubscriptionId: $lessonSubscriptionId
    returnUrl: $returnUrl
    promocodeId: $promocodeId
  ) {
    data
  }
}
    `;
export function useCreateLessonPurchaseOrderMutation(baseOptions) {
    return Apollo.useMutation(CreateLessonPurchaseOrderDocument, baseOptions);
}
export const CreateLessonPurchaseOrderWithoutScheduleDocument = gql `
    mutation CreateLessonPurchaseOrderWithoutSchedule($lessonSubscriptionId: Int!, $returnUrl: String!, $promocodeId: Int) {
  createLessonPurchaseOrderWithoutSchedule(
    lessonSubscriptionId: $lessonSubscriptionId
    returnUrl: $returnUrl
    promocodeId: $promocodeId
  ) {
    data
  }
}
    `;
export function useCreateLessonPurchaseOrderWithoutScheduleMutation(baseOptions) {
    return Apollo.useMutation(CreateLessonPurchaseOrderWithoutScheduleDocument, baseOptions);
}
export const CreateOnlineProgramOrderDocument = gql `
    mutation CreateOnlineProgramOrder($firstName: String!, $instagramUsername: String!, $lastName: String!, $phoneNumber: String!, $programId: Int!) {
  createOnlineProgramOrder(
    firstName: $firstName
    instagramUsername: $instagramUsername
    lastName: $lastName
    phoneNumber: $phoneNumber
    programId: $programId
  ) {
    data
    signature
  }
}
    `;
export function useCreateOnlineProgramOrderMutation(baseOptions) {
    return Apollo.useMutation(CreateOnlineProgramOrderDocument, baseOptions);
}
export const ChooseSubscriptionListDocument = gql `
    query ChooseSubscriptionList {
  viewer {
    id
    student {
      balletWithLock: lessonSubscriptions(forPointe: false, isFlexible: false) {
        ...LessonSubscriptionData
      }
      ballet: lessonSubscriptions(forPointe: false, isFlexible: true) {
        ...LessonSubscriptionData
      }
      pointe: lessonSubscriptions(forPointe: true, isFlexible: false) {
        ...LessonSubscriptionData
      }
    }
  }
}
    ${LessonSubscriptionDataFragmentDoc}`;
export function useChooseSubscriptionListQuery(baseOptions) {
    return Apollo.useQuery(ChooseSubscriptionListDocument, baseOptions);
}
export function useChooseSubscriptionListLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(ChooseSubscriptionListDocument, baseOptions);
}
export const CreateSubscriptionFreezingDocument = gql `
    mutation CreateSubscriptionFreezing($lessonPurchaseId: Int!, $startDate: Date!, $durationDays: Int!, $reference: Upload!) {
  createSubscriptionFreezing(
    lessonPurchaseId: $lessonPurchaseId
    startDate: $startDate
    durationDays: $durationDays
    reference: $reference
  ) {
    errors {
      field
      errors {
        code
        message
      }
    }
  }
}
    `;
export function useCreateSubscriptionFreezingMutation(baseOptions) {
    return Apollo.useMutation(CreateSubscriptionFreezingDocument, baseOptions);
}
export const IndividualFilterDocument = gql `
    query IndividualFilter {
  locations {
    id
    name
    isAvailable
  }
  halls {
    city
    id
  }
}
    `;
export function useIndividualFilterQuery(baseOptions) {
    return Apollo.useQuery(IndividualFilterDocument, baseOptions);
}
export function useIndividualFilterLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(IndividualFilterDocument, baseOptions);
}
export const PurchasesDocument = gql `
    query Purchases($userId: Int!) {
  userPurchases(userId: $userId) {
    id
    lessonsLeft
    availableMovements
    createdAt
    freezeLeft
    lessonSubscription {
      available
      description
      freezeDaysCount
      id
      lessonsCount
      movesCount
      name
      price
    }
    lessonTemplates {
      ...IndividualScheduleData
    }
  }
}
    ${IndividualScheduleDataFragmentDoc}`;
export function usePurchasesQuery(baseOptions) {
    return Apollo.useQuery(PurchasesDocument, baseOptions);
}
export function usePurchasesLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(PurchasesDocument, baseOptions);
}
export const LessonTemplatesPurchaseDocument = gql `
    mutation LessonTemplatesPurchase($login: String!, $password: String!, $lessonPurchaseId: Int!) {
  authenticate(login: $login, password: $password) {
    user {
      lessonTemplates(lessonPurchaseId: $lessonPurchaseId) {
        ...IndividualScheduleData
      }
    }
  }
}
    ${IndividualScheduleDataFragmentDoc}`;
export function useLessonTemplatesPurchaseMutation(baseOptions) {
    return Apollo.useMutation(LessonTemplatesPurchaseDocument, baseOptions);
}
export const MyScheduleDocument = gql `
    query MySchedule($year: Int!, $week: Int!) {
  viewer {
    id
    student {
      schedule(year: $year, week: $week) {
        id
        hall {
          id
          name
          city
          street
          lat
          lng
          locations {
            id
            name
            color
            isAvailable
          }
        }
        skillLevel {
          id
          name
        }
        type {
          id
          name
        }
        time
        coach {
          id
          firstName
          lastName
        }
        lessonPurchase {
          id
          freezeLeft
          lessonsLeft
          lessonTemplates {
            id
            freePlaceCount
          }
        }
        lessonTemplate {
          ...IndividualScheduleData
        }
      }
    }
  }
}
    ${IndividualScheduleDataFragmentDoc}`;
export function useMyScheduleQuery(baseOptions) {
    return Apollo.useQuery(MyScheduleDocument, baseOptions);
}
export function useMyScheduleLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(MyScheduleDocument, baseOptions);
}
export const LogoutDocument = gql `
    mutation Logout {
  logout {
    isSuccess
  }
}
    `;
export function useLogoutMutation(baseOptions) {
    return Apollo.useMutation(LogoutDocument, baseOptions);
}
export const UpdatePersonalDataDocument = gql `
    mutation UpdatePersonalData($firstName: String!, $lastName: String!, $birthday: Date, $instagram: String, $phoneNumber: String!, $verificationCode: String) {
  updateUser(
    firstName: $firstName
    lastName: $lastName
    birthday: $birthday
    instagram: $instagram
    phoneNumber: $phoneNumber
    verificationCode: $verificationCode
  ) {
    user {
      id
      firstName
      lastName
      birthday
      instagram
      phoneNumber
      avatarUrl
    }
    errors {
      ...errorsData
    }
  }
}
    ${ErrorsDataFragmentDoc}`;
export function useUpdatePersonalDataMutation(baseOptions) {
    return Apollo.useMutation(UpdatePersonalDataDocument, baseOptions);
}
export const SendPhoneVerificationCodeDocument = gql `
    mutation SendPhoneVerificationCode($phoneNumber: String!) {
  sendPhoneVerificationCode(phoneNumber: $phoneNumber) {
    errors {
      ...errorsData
    }
  }
}
    ${ErrorsDataFragmentDoc}`;
export function useSendPhoneVerificationCodeMutation(baseOptions) {
    return Apollo.useMutation(SendPhoneVerificationCodeDocument, baseOptions);
}
export const PersonalDataDocument = gql `
    query PersonalData {
  viewer {
    id
    firstName
    lastName
    phoneNumber
    avatarUrl
    instagram
    birthday
  }
}
    `;
export function usePersonalDataQuery(baseOptions) {
    return Apollo.useQuery(PersonalDataDocument, baseOptions);
}
export function usePersonalDataLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(PersonalDataDocument, baseOptions);
}
export const StudentSubscriptionDocument = gql `
    query StudentSubscription {
  viewer {
    id
    student {
      purchasedLessonSubscriptions {
        id
        lessonSubscription {
          id
          name
          lessonsCount
          movesCount
          freezeDaysCount
        }
        freezeLeft
        lessonsUsed
        movementsUsed
        expireAt
        schedule {
          ...IndividualScheduleData
        }
      }
    }
  }
}
    ${IndividualScheduleDataFragmentDoc}`;
export function useStudentSubscriptionQuery(baseOptions) {
    return Apollo.useQuery(StudentSubscriptionDocument, baseOptions);
}
export function useStudentSubscriptionLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(StudentSubscriptionDocument, baseOptions);
}
export const TrialFilterDocument = gql `
    query TrialFilter {
  locations {
    id
    name
    isAvailable
  }
  halls {
    city
    id
  }
}
    `;
export function useTrialFilterQuery(baseOptions) {
    return Apollo.useQuery(TrialFilterDocument, baseOptions);
}
export function useTrialFilterLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(TrialFilterDocument, baseOptions);
}
export const TrialLessonsDocument = gql `
    query TrialLessons($locationId: Int, $time: LessonTime, $skillLevelId: Int, $lessonTypeId: Int) {
  individualLessonsSchedule(
    locationId: $locationId
    time: $time
    skillLevelId: $skillLevelId
    lessonTypeId: $lessonTypeId
  ) {
    ...IndividualScheduleData
  }
}
    ${IndividualScheduleDataFragmentDoc}`;
export function useTrialLessonsQuery(baseOptions) {
    return Apollo.useQuery(TrialLessonsDocument, baseOptions);
}
export function useTrialLessonsLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(TrialLessonsDocument, baseOptions);
}
export const ChangeScheduleDocument = gql `
    mutation ChangeSchedule($lessonTemplatesIds: [Int!]!, $userId: Int!, $lessonPurchaseId: Int!) {
  changeSchedule(
    lessonPurchaseId: $lessonPurchaseId
    lessonTemplatesIds: $lessonTemplatesIds
    userId: $userId
  ) {
    isSuccess
  }
}
    `;
export function useChangeScheduleMutation(baseOptions) {
    return Apollo.useMutation(ChangeScheduleDocument, baseOptions);
}
export const AddCommentOnStudentDocument = gql `
    mutation AddCommentOnStudent($text: String!, $visitorId: Int!) {
  addUserCoachComment(text: $text, visitorId: $visitorId) {
    comment {
      id
      text
      createdAt
    }
    errors {
      ...errorsData
    }
  }
}
    ${ErrorsDataFragmentDoc}`;
export function useAddCommentOnStudentMutation(baseOptions) {
    return Apollo.useMutation(AddCommentOnStudentDocument, baseOptions);
}
export const CloseLessonButtonDocument = gql `
    query CloseLessonButton($lessonId: Int!) {
  lessonDetail(lessonId: $lessonId) {
    id
    isClosed
  }
}
    `;
export function useCloseLessonButtonQuery(baseOptions) {
    return Apollo.useQuery(CloseLessonButtonDocument, baseOptions);
}
export function useCloseLessonButtonLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(CloseLessonButtonDocument, baseOptions);
}
export const CloseLessonDocument = gql `
    mutation CloseLesson($lessonId: Int!) {
  closeLesson(lessonId: $lessonId) {
    lesson {
      id
      isClosed
    }
  }
}
    `;
export function useCloseLessonMutation(baseOptions) {
    return Apollo.useMutation(CloseLessonDocument, baseOptions);
}
export const TeacherCurrentLessonIdDocument = gql `
    query TeacherCurrentLessonId {
  viewer {
    id
    nearestLesson {
      id
    }
  }
}
    `;
export function useTeacherCurrentLessonIdQuery(baseOptions) {
    return Apollo.useQuery(TeacherCurrentLessonIdDocument, baseOptions);
}
export function useTeacherCurrentLessonIdLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(TeacherCurrentLessonIdDocument, baseOptions);
}
export const TeacherCurrentLessonDetailCardDocument = gql `
    query TeacherCurrentLessonDetailCard {
  viewer {
    id
    nearestLesson {
      id
      hall {
        id
        street
      }
      time
      duration
      skillLevel {
        id
        name
      }
      ...lessonDetailVisitAnalytics
    }
  }
}
    ${LessonDetailVisitAnalyticsFragmentDoc}`;
export function useTeacherCurrentLessonDetailCardQuery(baseOptions) {
    return Apollo.useQuery(TeacherCurrentLessonDetailCardDocument, baseOptions);
}
export function useTeacherCurrentLessonDetailCardLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(TeacherCurrentLessonDetailCardDocument, baseOptions);
}
export const TeacherLessonDetailCardByIdDocument = gql `
    query TeacherLessonDetailCardById($id: Int!) {
  lessonDetail(lessonId: $id) {
    id
    hall {
      id
      street
    }
    time
    duration
    skillLevel {
      id
      name
    }
    isClosed
    ...lessonDetailVisitAnalytics
  }
}
    ${LessonDetailVisitAnalyticsFragmentDoc}`;
export function useTeacherLessonDetailCardByIdQuery(baseOptions) {
    return Apollo.useQuery(TeacherLessonDetailCardByIdDocument, baseOptions);
}
export function useTeacherLessonDetailCardByIdLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(TeacherLessonDetailCardByIdDocument, baseOptions);
}
export const LessonDetailStudentsListDocument = gql `
    query LessonDetailStudentsList($lessonId: Int!) {
  lessonDetail(lessonId: $lessonId) {
    id
    isClosed
    visitors {
      id
      visited
      isFirstVisit
      user {
        id
        firstName
        lastName
        avatarUrl
      }
    }
    ...lessonDetailVisitAnalytics
  }
}
    ${LessonDetailVisitAnalyticsFragmentDoc}`;
export function useLessonDetailStudentsListQuery(baseOptions) {
    return Apollo.useQuery(LessonDetailStudentsListDocument, baseOptions);
}
export function useLessonDetailStudentsListLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(LessonDetailStudentsListDocument, baseOptions);
}
export const NavProfileDocument = gql `
    query NavProfile {
  viewer {
    id
    firstName
    avatarUrl
    lessons {
      edges {
        node {
          id
          time
          hall {
            street
            name
          }
          visitAnalytics {
            inAll
            notPaid
            trialLesson
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
    }
  }
}
    `;
export function useNavProfileQuery(baseOptions) {
    return Apollo.useQuery(NavProfileDocument, baseOptions);
}
export function useNavProfileLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(NavProfileDocument, baseOptions);
}
export const TeacherNextLessonDocument = gql `
    query TeacherNextLesson {
  viewer {
    id
    nearestLesson {
      id
      time
      hall {
        id
        city
        street
      }
      duration
      skillLevel {
        id
        name
      }
    }
  }
}
    `;
export function useTeacherNextLessonQuery(baseOptions) {
    return Apollo.useQuery(TeacherNextLessonDocument, baseOptions);
}
export function useTeacherNextLessonLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(TeacherNextLessonDocument, baseOptions);
}
export const TeacherScheduleAggregationsDocument = gql `
    query TeacherScheduleAggregations($before: String, $after: String) {
  viewer {
    id
    lessons(before: $before, after: $after, first: 20) {
      edges {
        node {
          id
          time
          bookingPlacesCount
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
}
    `;
export function useTeacherScheduleAggregationsQuery(baseOptions) {
    return Apollo.useQuery(TeacherScheduleAggregationsDocument, baseOptions);
}
export function useTeacherScheduleAggregationsLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(TeacherScheduleAggregationsDocument, baseOptions);
}
export const TeacherScheduleDaysDocument = gql `
    query TeacherScheduleDays($before: String, $after: String, $first: Int, $last: Int) {
  viewer {
    id
    lessons(before: $before, after: $after, first: $first, last: $last) {
      edges {
        node {
          id
          bookingPlacesCount
          trialPlacesCount
          time
          visitAnalytics {
            inAll
            shouldPresent
            notPaid
            trialLesson
            willAbsent
          }
          hall {
            id
            street
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
}
    `;
export function useTeacherScheduleDaysQuery(baseOptions) {
    return Apollo.useQuery(TeacherScheduleDaysDocument, baseOptions);
}
export function useTeacherScheduleDaysLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(TeacherScheduleDaysDocument, baseOptions);
}
export const StudentCoachCommentDocument = gql `
    query StudentCoachComment($id: Int!) {
  lessonVisitor(id: $id) {
    id
    user {
      id
      coachComments {
        id
        text
        commentator {
          id
          firstName
          lastName
        }
      }
    }
  }
}
    `;
export function useStudentCoachCommentQuery(baseOptions) {
    return Apollo.useQuery(StudentCoachCommentDocument, baseOptions);
}
export function useStudentCoachCommentLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(StudentCoachCommentDocument, baseOptions);
}
export const VisitorTogglePaymentDocument = gql `
    mutation VisitorTogglePayment($visitorId: Int!, $isPaid: Boolean!) {
  togglePayment(visitorId: $visitorId, isPaid: $isPaid) {
    lessonVisitor {
      id
    }
    errors {
      ...errorsData
    }
  }
}
    ${ErrorsDataFragmentDoc}`;
export function useVisitorTogglePaymentMutation(baseOptions) {
    return Apollo.useMutation(VisitorTogglePaymentDocument, baseOptions);
}
export const VisitorToggleVisitDocument = gql `
    mutation VisitorToggleVisit($visitorId: Int!, $isVisited: Boolean!) {
  toggleVisit(visitorId: $visitorId, isVisited: $isVisited) {
    lessonVisitor {
      id
      visited
    }
    errors {
      ...errorsData
    }
  }
}
    ${ErrorsDataFragmentDoc}`;
export function useVisitorToggleVisitMutation(baseOptions) {
    return Apollo.useMutation(VisitorToggleVisitDocument, baseOptions);
}
export const NewsPageArticleDocument = gql `
    query NewsPageArticle($slug: String!) {
  newsCategory(slug: $slug) {
    lastArticles {
      image
      id
      title
      slug
      category {
        id
        description
        name
        slug
      }
    }
  }
}
    `;
export function useNewsPageArticleQuery(baseOptions) {
    return Apollo.useQuery(NewsPageArticleDocument, baseOptions);
}
export function useNewsPageArticleLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(NewsPageArticleDocument, baseOptions);
}
export const ConcertsDocument = gql `
    query Concerts {
  concerts {
    meta
    time
    hall {
      street
      city
      id
      name
    }
    id
    title
  }
}
    `;
export function useConcertsQuery(baseOptions) {
    return Apollo.useQuery(ConcertsDocument, baseOptions);
}
export function useConcertsLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(ConcertsDocument, baseOptions);
}
export const CreateConcertPurchaseDocument = gql `
    mutation CreateConcertPurchase($concertId: Int!, $email: String!, $placeIds: [Int!]!, $phoneNumber: String!, $expireTime: Time!, $returnUrl: String!) {
  createConcertPurchase(
    concertId: $concertId
    email: $email
    placeIds: $placeIds
    expireTime: $expireTime
    phoneNumber: $phoneNumber
    returnUrl: $returnUrl
  ) {
    data
  }
}
    `;
export function useCreateConcertPurchaseMutation(baseOptions) {
    return Apollo.useMutation(CreateConcertPurchaseDocument, baseOptions);
}
export const BookingTicketDocument = gql `
    mutation BookingTicket($concertId: Int!, $rowPlaceIds: [Int!]!) {
  bookingTicket(concertId: $concertId, rowPlaceIds: $rowPlaceIds)
}
    `;
export function useBookingTicketMutation(baseOptions) {
    return Apollo.useMutation(BookingTicketDocument, baseOptions);
}
export const CreateTicketAndSendItDocument = gql `
    mutation CreateTicketAndSendIt($amount: Int!, $concertId: Int!, $email: String!, $phoneNumber: String!, $places: [Int!]!, $purchaseDate: DateTime!, $purchaseUuid: String!) {
  createTicketAndSendIt(
    amount: $amount
    concertId: $concertId
    email: $email
    phonenumber: $phoneNumber
    places: $places
    purchaseDate: $purchaseDate
    purchaseUuid: $purchaseUuid
  ) {
    isSuccess
  }
}
    `;
export function useCreateTicketAndSendItMutation(baseOptions) {
    return Apollo.useMutation(CreateTicketAndSendItDocument, baseOptions);
}
export const StudioEventsDocument = gql `
    query StudioEvents {
  studioEvents {
    description
    id
    maxMembers
    miniDescription
    price
    title
    place {
      id
      city
      images
      lat
      lng
      name
      street
    }
  }
}
    `;
export function useStudioEventsQuery(baseOptions) {
    return Apollo.useQuery(StudioEventsDocument, baseOptions);
}
export function useStudioEventsLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(StudioEventsDocument, baseOptions);
}
export const CreateEventPurchaseDocument = gql `
    mutation CreateEventPurchase($email: String!, $eventId: Int!, $firstName: String!, $lastName: String!, $phoneNumber: String!, $returnUrl: String!, $tgOrInst: String) {
  createEventPurchase(
    email: $email
    eventId: $eventId
    firstName: $firstName
    lastName: $lastName
    phoneNumber: $phoneNumber
    returnUrl: $returnUrl
    tgOrInst: $tgOrInst
  ) {
    data
  }
}
    `;
export function useCreateEventPurchaseMutation(baseOptions) {
    return Apollo.useMutation(CreateEventPurchaseDocument, baseOptions);
}
export const GenericPageDocument = gql `
    query GenericPage($slug: String!) {
  page(slug: $slug) {
    id
    seoTitle
    seoDescription
    title
    subtitle
    content
  }
}
    `;
export function useGenericPageQuery(baseOptions) {
    return Apollo.useQuery(GenericPageDocument, baseOptions);
}
export function useGenericPageLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(GenericPageDocument, baseOptions);
}
export const LoginCheckDocument = gql `
    query LoginCheck {
  viewer {
    id
    isCoach
  }
}
    `;
export function useLoginCheckQuery(baseOptions) {
    return Apollo.useQuery(LoginCheckDocument, baseOptions);
}
export function useLoginCheckLazyQuery(baseOptions) {
    return Apollo.useLazyQuery(LoginCheckDocument, baseOptions);
}

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as classNames from "classnames";
import * as React from "react";
import ShowPasswordToggle from "../ShowPasswordToggle/ShowPasswordToggle";
import * as s from "./Input.sss";
export const Input = (_a) => {
    var { invalid, value, className, type } = _a, props = __rest(_a, ["invalid", "value", "className", "type"]);
    const [showPassword, setShowPassword] = React.useState(false);
    const isPassword = () => {
        return type === "password";
    };
    const typeState = showPassword ? "text" : type;
    return (React.createElement("div", { className: s.root },
        React.createElement("input", Object.assign({ className: classNames(s.input, className, {
                [s.invalid]: invalid,
                [s.passwordInput]: isPassword(),
            }), type: typeState, value: value }, props)),
        isPassword() ? (React.createElement(ShowPasswordToggle, { showPassword: showPassword, toggleShowPassword: () => setShowPassword(!showPassword) })) : null));
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cx from "classnames";
import * as React from "react";
import ReactModal from "react-modal";
import CloseIcon from "@static/svg/close.svg";
import HeartsIcon from "@static/svg/hearts.svg";
import s from "./Modal.sss";
export const Modal = (_a) => {
    var { className, theme, withClose = false, overlayClassName, htmlOpenClassName, bodyOpenClassName, isOpen, onRequestClose } = _a, props = __rest(_a, ["className", "theme", "withClose", "overlayClassName", "htmlOpenClassName", "bodyOpenClassName", "isOpen", "onRequestClose"]);
    return (React.createElement(ReactModal, Object.assign({ appElement: typeof window !== "undefined"
            ? document.querySelector("#root")
            : undefined, className: {
            base: cx(s.root, s[theme], typeof className === "object" ? className.base : className),
            afterOpen: cx(s.afterOpen, typeof className === "object" ? className.afterOpen : undefined),
            beforeClose: cx(s.beforeClose, typeof className === "object" ? className.beforeClose : undefined),
        }, overlayClassName: {
            base: cx(s.overlay, typeof overlayClassName === "object" ? overlayClassName.base : className),
            afterOpen: cx(s.afterOpen, typeof overlayClassName === "object"
                ? overlayClassName.afterOpen
                : undefined),
            beforeClose: cx(s.beforeClose, typeof overlayClassName === "object"
                ? overlayClassName.beforeClose
                : undefined),
        }, htmlOpenClassName: cx(s.htmlOpen, htmlOpenClassName), bodyOpenClassName: cx(s.bodyOpen, bodyOpenClassName), closeTimeoutMS: 300, isOpen: isOpen }, props),
        theme === "orange" && (React.createElement(HeartsIcon, { className: s.heartsIconTop, style: localStorage.getItem("cityModal") ? { display: "none" } : undefined })),
        withClose && (React.createElement("div", { className: s.close },
            React.createElement(CloseIcon, { className: s.closeIcon, onClick: onRequestClose }))),
        props.children,
        theme === "orange" && (React.createElement(HeartsIcon, { className: s.heartsIconBottom, style: localStorage.getItem("cityModal") ? { display: "none" } : undefined }))));
};

import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckoutStartDateInputContainer, } from "@containers/student/CheckoutStartDateInput";
import { Button } from "@components/student/Button";
import { Link } from "@components/ui/Link";
import { paymentDataToFormInputs } from "@utils/common";
import { getLessonDeclension } from "@utils/wordDeclension";
import * as s from "./CheckoutSummary.sss";
import { Checkbox } from "@/IndexLanding/components/Checkbox";
import { publicContracts } from "@/urls";
export const CheckoutSummary = ({ lessonsCount, isTrial, schedule, paymentData, className, subscriptionId, }) => {
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState();
    const [isAcquainted, setAcquainted] = useState(false);
    const isEvent = "eventId" in schedule;
    const isConcert = "concertId" in schedule;
    let subscriptionName;
    if (isTrial) {
        subscriptionName = t("student~Попробовать");
    }
    else if (isEvent) {
        subscriptionName = t("student~Event");
    }
    else if (isConcert) {
        subscriptionName = t("student~Концкрт");
    }
    else if (lessonsCount === 1 && subscriptionId !== 10) {
        subscriptionName = t("student~Разовое занятие");
    }
    else if (lessonsCount === 1 && subscriptionId === 10) {
        subscriptionName = t("student~Пробне заняття");
    }
    else {
        subscriptionName = `${lessonsCount} ${getLessonDeclension(t, lessonsCount)}`;
    }
    return (React.createElement("div", { className: className },
        React.createElement("div", { className: s.card },
            !isEvent && !isConcert && (React.createElement("div", { className: s.priceSection },
                React.createElement("div", { className: s.subscriptionName },
                    subscriptionName,
                    React.createElement(CheckoutStartDateInputContainer, { schedule: schedule, value: startDate, onChange: setStartDate })),
                React.createElement("div", { className: s.subscriptionPrice }, paymentData === null || paymentData === void 0 ? void 0 :
                    paymentData.amount,
                    " \u0433\u0440\u043D"))),
            React.createElement("div", { className: s.bottomSection },
                React.createElement("div", { className: s.summarySection },
                    React.createElement("div", { className: s.summary }, t("student~Итого:")),
                    React.createElement("div", { className: s.summaryPrice }, paymentData === null || paymentData === void 0 ? void 0 :
                        paymentData.amount,
                        " \u0433\u0440\u043D")),
                React.createElement("form", { className: s.form, method: "POST", action: "https://www.liqpay.ua/api/3/checkout" },
                    Boolean(paymentData) && paymentDataToFormInputs(paymentData),
                    React.createElement(Button, { className: s.payButton, type: "submit", disabled: !isAcquainted || !paymentData }, t("student~Оплатить"))),
                React.createElement(Checkbox, { checked: isAcquainted, onCheck: () => setAcquainted(prev => !prev), className: s.acquainted },
                    React.createElement("div", { className: s.legalInfo },
                        "\u042F \u043E\u0437\u043D\u0430\u0439\u043E\u043C\u043B\u0435\u043D\u0438\u0439(-\u0430) \u0437",
                        " ",
                        React.createElement(Link, { to: publicContracts, target: "_blank" }, "\u0434\u043E\u0433\u043E\u0432\u043E\u0440\u043E\u043C \u043E\u0444\u0435\u0440\u0442\u0438"),
                        " ",
                        "\u0439",
                        " ",
                        React.createElement(Link, { to: "/rules.pdf", target: "_blank" }, "\u043F\u0440\u0430\u0432\u0438\u043B\u0430\u043C\u0438")))))));
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useLoginCheckQuery } from '@graphql';
import { authLogin } from '@urls';
export const LoginRequiredRoute = (_a) => {
    var { component: Component } = _a, rest = __rest(_a, ["component"]);
    const { data, loading, error } = useLoginCheckQuery();
    if (loading) {
        return null;
    }
    if (error) {
        throw error;
    }
    const { id } = data.viewer || {};
    return (React.createElement(Route, Object.assign({}, rest, { render: props => id ? (React.createElement(Component, Object.assign({}, props))) : (React.createElement(Redirect, { to: {
                pathname: authLogin
            } })) })));
};

import dayjs from "dayjs";
import * as React from "react";
import { CheckoutStartDateInput } from "@components/student/CheckoutStartDateInput";
import { useCheckoutStartDateInputInfoQuery } from "@graphql";
export const CheckoutStartDateInputContainer = ({ value, schedule, onChange }) => {
    const { loading, data, error } = useCheckoutStartDateInputInfoQuery({
        variables: { lessonTemplatesIds: [] },
        onCompleted: ({ subscriptionStartDates }) => {
            onChange(dayjs(subscriptionStartDates[0]));
        },
    });
    if (loading) {
        return null;
    }
    if (error) {
        throw error;
    }
    if (value) {
        if (data) {
            const dates = data.subscriptionStartDates.map(date => dayjs(date));
            return (React.createElement(CheckoutStartDateInput, { availableDates: dates, value: value, onChange: onChange }));
        }
        throw new Error("Empty render");
    }
    return null;
};

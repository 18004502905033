import React, { useMemo } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { WelcomeModal } from '@/containers/student/WelcomeModal';
import { trialLessonModalHash, welcomeModalHash } from '@/urls';
import { TrialLessonModalContainer } from '@/containers/site/TrialLessonModal';
import { LevelNotification } from '@/components/student/LevelNotification';
import { Loader } from '../Loader';
import { useCitiesQuery } from '@/graphql';
export const AppInitializer = ({ children }) => {
    const { data: cityData } = useCitiesQuery();
    const location = useLocation();
    const [modalShown, setModalShown] = React.useState(false);
    const [levelNotification, setLevelNotification] = React.useState({
        following: false,
        id: null,
        warningDescription: null,
        cityInput: null,
    });
    const cities = useMemo(() => cityData === null || cityData === void 0 ? void 0 : cityData.cities.map(city => city), [cityData]);
    const handleAgreeCity = () => {
        setLevelNotification({
            following: false,
            id: null,
            warningDescription: null,
            cityInput: null,
        });
    };
    const handleLaterCity = () => {
        setLevelNotification({
            following: false,
            id: null,
            warningDescription: null,
            cityInput: null,
        });
    };
    React.useEffect(() => {
        if (!modalShown && !localStorage.getItem("cityChoised")) {
            setModalShown(true);
            setLevelNotification({
                following: true,
                id: null,
                warningDescription: null,
                cityInput: null,
            });
            !localStorage.getItem("cityName") && localStorage.setItem("cityName", "Київ");
            localStorage.setItem("cityChoised", "true");
        }
    }, [location.pathname, modalShown]);
    if (typeof window === 'undefined') {
        return React.createElement(Loader, null);
    }
    return React.createElement(React.Fragment, null,
        children,
        React.createElement(Route, { render: ({ history }) => (React.createElement(React.Fragment, null,
                React.createElement(WelcomeModal, { isOpen: history.location.hash === welcomeModalHash, onRequestClose: () => history.replace(Object.assign(Object.assign({}, history.location), { hash: "" })) }),
                React.createElement(ToastContainer, null),
                React.createElement(TrialLessonModalContainer, { isOpen: history.location.hash === trialLessonModalHash, onRequestClose: () => history.replace(Object.assign(Object.assign({}, history.location), { hash: "" })) }),
                levelNotification.following && (React.createElement(LevelNotification, { isOpen: levelNotification.following, agreeLevel: handleAgreeCity, cancelAddition: handleLaterCity, title: "Bonjour!", warningDescription: "\u0411\u0443\u0434\u044C \u043B\u0430\u0441\u043A\u0430, \u0432\u0438\u0431\u0435\u0440\u0438 \u043C\u0456\u0441\u0442\u043E \u0437\u0456 \u0441\u043F\u0438\u0441\u043A\u0443.\n              \u0426\u0435 \u0434\u043E\u043F\u043E\u043C\u043E\u0436\u0435 \u0432\u0456\u0434\u043E\u0431\u0440\u0430\u0437\u0438\u0442\u0438 \u043A\u043E\u0440\u0435\u043A\u0442\u043D\u0443 \u0456\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0456\u044E \u0434\u043B\u044F \u0442\u0435\u0431\u0435.", citiesInput: cities })))) }));
};

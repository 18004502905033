import * as classNames from "classnames";
import dayjs from "dayjs";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@components/student/Button";
import { levelRepresentation, typeRepresentation, } from "@constants";
import ArrowToBottom from "@svg/arrowToBottom.svg";
import ArrowToTop from "@svg/arrowToTop.svg";
import { LevelNotification } from "../LevelNotification";
import * as s from "./ScheduleCard.sss";
import { getTimeCorrected } from "@/utils/common";
export const ScheduleCard = ({ className, lessonSchedule, calendarView = false, isChosen = false, isTrial = false, lessonPurchase, onButtonClick, addChange, setAddChange, createFreezeModal, }) => {
    var _a;
    const { t } = useTranslation();
    addChange;
    setAddChange;
    addChange;
    setAddChange;
    const [isShowAddition, setShowAddition] = React.useState(false);
    const [trialButton, setTrialButton] = React.useState(false);
    const [levelNotification, setLevelNotification] = React.useState(false);
    const [countNotification, setCountNotification] = React.useState(false);
    React.useEffect(() => {
        !addChange ? setTrialButton(false) : null;
    }, [addChange]);
    const { hall, skillLevel, coach, time, type, days, weekday } = lessonSchedule;
    const timeCorrect = getTimeCorrected(time);
    const dateString = time.slice(0, 10);
    const formattedDate = dayjs(dateString).format("DD MMMM");
    const dateLesson = formattedDate;
    const timeLesson = time.slice(11, 16);
    const locationName = hall.locations.map(loc => loc.name);
    const addSchedule = () => {
        onButtonClick && onButtonClick("add");
        setAddChange(true);
        setTrialButton(true);
        const level = levelRepresentation[skillLevel.id];
        const lesson = typeRepresentation[type.id]
            .split(" ")
            .join("");
        if (lesson !== "Пуанти" && (level === "MIDDLE" || level === "HIGH")) {
            setLevelNotification(true);
        }
        else if (level === "START") {
            return null;
        }
    };
    const cancelAddition = () => {
        onButtonClick && onButtonClick("cancel");
        setTrialButton(false);
        setCountNotification(true);
    };
    const handleButtonClick = event => {
        const target = event.target;
        if (target.id === "buttonAdd") {
            addSchedule();
        }
        else if (target.id === "buttonCancel") {
            cancelAddition();
        }
        else if (target.id === "buttonAddTrial") {
            return;
        }
        setShowAddition(!isShowAddition);
    };
    const colors = levelRepresentation[skillLevel.id] === "MIDDLE"
        ? "#F6C972"
        : levelRepresentation[skillLevel.id] === "HIGH"
            ? "#F67280"
            : levelRepresentation[skillLevel.id] === "START"
                ? "#6EE1A9"
                : levelRepresentation[skillLevel.id] === "START+"
                    ? "#f8b195"
                    : "";
    return (React.createElement("div", { className: classNames(s.root, className) },
        React.createElement("div", { className: classNames(s.groupInfo, { [s.calendarView]: calendarView }, { [s.isChosen]: isChosen }, { [s.isShowButtons]: isShowAddition }), id: "scheduleCard", style: {
                borderLeftColor: isChosen
                    ? "#7A4694"
                    : ((_a = hall.locations[0]) === null || _a === void 0 ? void 0 : _a.color) || "#fff",
            }, onClick: handleButtonClick },
            React.createElement("div", { className: classNames(s.infoItem, { [s.hideInfo]: calendarView }) },
                React.createElement("span", { className: s.infoProp },
                    t("student~Адрес"),
                    ": "),
                t("student~ул."),
                " ",
                hall.street),
            React.createElement("div", { className: classNames(s.infoItem, s.capitalize) },
                React.createElement("span", { className: classNames(s.infoProp) },
                    t("student~День"),
                    ": "),
                dayjs(dateString).format("dddd"),
                React.createElement("br", null),
                dateLesson),
            React.createElement("div", { className: classNames(s.infoItem, { [s.timeInfo]: calendarView }) },
                React.createElement("span", { className: classNames(s.infoProp, { [s.hideInfo]: calendarView }) },
                    t("student~Время"),
                    ":",
                    " "),
                timeCorrect),
            React.createElement("div", { className: classNames(s.infoItem, { [s.levelInfo]: calendarView }) },
                React.createElement("span", { className: classNames(s.infoProp, { [s.hideInfo]: calendarView }) },
                    t("student~Уровень"),
                    ":",
                    " "),
                React.createElement("span", { style: {
                        color: colors,
                    } }, type.id !== 3 &&
                    levelRepresentation[skillLevel.id])),
            React.createElement("div", { className: classNames(s.infoItem, { [s.typeInfo]: calendarView }) },
                React.createElement("span", { className: classNames(s.infoProp, { [s.info]: calendarView }) },
                    t("student~Направление"),
                    ":"),
                typeRepresentation[type.id]),
            coach && (React.createElement("div", { className: classNames(s.infoItem, { [s.typeInfo]: calendarView }) },
                React.createElement("div", { className: classNames({ [s.hideInfo]: calendarView }) },
                    React.createElement("span", { className: s.infoProp },
                        t("student~Педагог"),
                        ": "),
                    coach.firstName,
                    " ",
                    coach.lastName))),
            (isShowAddition || !isChosen) && (React.createElement("div", { className: classNames({ [s.infoItem]: calendarView }) },
                React.createElement("div", { className: s.location },
                    React.createElement("span", { className: s.infoProp },
                        t("student~Метро"),
                        ": "),
                    locationName.join(", ")))),
            createFreezeModal && (React.createElement(Button, { className: s.ghostButton, disabled: Boolean(!(lessonPurchase === null || lessonPurchase === void 0 ? void 0 : lessonPurchase.freezeLeft)), onClick: () => createFreezeModal({
                    isOpen: true,
                    lessonPurchaseId: lessonPurchase.id,
                    freezeCount: lessonPurchase.freezeLeft,
                }) }, t("student~Заморозить"))),
            !createFreezeModal &&
                calendarView &&
                !isChosen &&
                (trialButton ? (React.createElement(Button, { id: "buttonAddTrial", className: classNames(s.ghostButton, s.cancel), onClick: cancelAddition }, t("student~Отменить добавление"))) : (React.createElement(Button, { id: "buttonAddTrial", className: s.ghostButton, onClick: addSchedule }, t("student~Записаться на занятие")))),
            React.createElement(Button, { theme: "clean", disabled: !onButtonClick, className: s.showAddition, onClick: handleButtonClick, type: "button" }, isShowAddition ? (React.createElement(ArrowToTop, { className: s.arrow })) : (onButtonClick && React.createElement(ArrowToBottom, { className: s.arrow })))),
        levelNotification ? (React.createElement(LevelNotification, { isOpen: levelNotification, agreeLevel: () => setLevelNotification(false), cancelAddition: cancelAddition })) : null));
};

import * as classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import SnowflakeIcon from "@static/svg/snowflake.svg";
import SquaresIcon from "@static/svg/squares.svg";
import * as s from "./SubscriptionCard.sss";
export const SubscriptionCard = ({ subscription, action, className, }) => {
    const { t } = useTranslation();
    const { name, lessonsCount, price, description, movesCount, freezeDaysCount, } = subscription;
    return (React.createElement("div", { className: classNames(s.root, className) },
        React.createElement("div", null,
            React.createElement("div", { className: s.name }, name),
            React.createElement("div", { className: s.price },
                "\u0437\u0430 ",
                price,
                " \u0433\u0440\u043D"),
            React.createElement("div", { className: s.pricePerLesson }, lessonsCount > 1 &&
                t("student~за {{price}} грн / занятие", {
                    price: Math.ceil(price / lessonsCount),
                })),
            React.createElement("div", { className: s.description }, description),
            movesCount || freezeDaysCount ? (React.createElement("div", { className: s.perks },
                movesCount > 0 && (React.createElement("div", { className: s.perk },
                    React.createElement(SquaresIcon, { className: s.perkIcon }),
                    t("student~Перенос {{movesCount}} занятия", { movesCount }))),
                freezeDaysCount > 0 && (React.createElement("div", { className: s.perk },
                    React.createElement(SnowflakeIcon, { className: s.perkIcon }),
                    t("student~Заморозка от 1 до 7 дней", {
                        freezeCount: freezeDaysCount,
                    }))))) : null),
        action && React.createElement("div", { className: s.action }, action)));
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { FORM_ERROR } from "final-form";
import * as React from "react";
import { withTypes } from "react-final-form";
import { useTranslation } from "react-i18next";
import { TrialLessonModal, } from "@components/site/TrialLessonModal";
import { safeFbq } from "@utils/analytics";
import { useCreateTrialLessonRecordMutation } from "@/graphql";
export const TrialLessonModalContainer = (_a) => {
    var { onRequestClose } = _a, props = __rest(_a, ["onRequestClose"]);
    const { t } = useTranslation();
    const [trialLessonRecord] = useCreateTrialLessonRecordMutation();
    const { Form } = withTypes();
    const onSubmit = ({ firstName, phoneNumber, instagram, }) => __awaiter(void 0, void 0, void 0, function* () {
        const city = localStorage.getItem('cityName');
        const { data } = yield trialLessonRecord({
            variables: {
                firstName,
                lastName: "",
                phoneNumber,
                instagram,
                city
            },
        });
        if (!data.createTrialLessonRecord.isSuccess) {
            return {
                [FORM_ERROR]: t("site~Виникла помилка, спробуйте повторити пізніше"),
            };
        }
        safeFbq("track", "Lead");
        safeFbq("track", "SubmitApplication");
        return undefined;
    });
    return (React.createElement(Form, { onSubmit: onSubmit, render: () => (React.createElement(TrialLessonModal, Object.assign({ onRequestClose: onRequestClose }, props))) }));
};

import * as classNames from "classnames";
import * as React from "react";
import { Helmet } from "react-helmet";
import { NavBarContainer } from "@containers/student/NavBar";
import { Footer } from "@components/student/Footer";
import { useDesktopOrWider } from "@utils/mediaQuery";
import * as s from "./BasePage.sss";
export const BasePage = ({ theme = "primary", isThemeTransform = true, className, children, }) => {
    const isDesktopOrWider = useDesktopOrWider();
    return (React.createElement("div", { className: classNames(s.root, className) },
        React.createElement(Helmet, null,
            React.createElement("link", { href: "https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap", rel: "stylesheet" })),
        React.createElement("div", { className: s.content },
            React.createElement(NavBarContainer, { theme: isThemeTransform
                    ? theme === "primary"
                        ? "bright"
                        : "primary-transparent"
                    : "primary" }),
            children),
        isDesktopOrWider && React.createElement(Footer, null)));
};

import { strict as assert } from "assert";
import i18next from "i18next";
import { isPhoneNumberValid } from "@utils/common";
import { LessonTime } from "@graphql";
function prettifyPhoneNumber(phoneOriginal) {
    const numberWithSpaces = [
        phoneOriginal.slice(0, 2),
        phoneOriginal.slice(2, 5),
        phoneOriginal.slice(5, 8),
        phoneOriginal.slice(8, 10),
        phoneOriginal.slice(10),
    ].join(" ");
    return `+${numberWithSpaces}`;
}
export const phoneMask = "+380 99 999 9999";
export const phoneMaskChar = " ";
export const smsPasswordMask = "999 999 999";
export const smsPasswordMaskChar = " ";
export const phoneNumberOriginal = "380633762917";
export const phoneNumber = prettifyPhoneNumber(phoneNumberOriginal);
export const phoneNumberToCall = `tel:+${phoneNumberOriginal}`;
export const emailAddress = "maryballetkyiv@gmail.com";
assert(isPhoneNumberValid(phoneNumberOriginal));
export const facebookLink = "https://www.facebook.com/maryballetkyiv/";
export const tiktokLink = "https://www.tiktok.com/@maryballetkyiv";
export const instagramLink = "https://www.instagram.com/mary.ballet.kyiv/";
export const telegramLink = "https://t.me/MaryBallet";
export const youtubeLink = "https://www.youtube.com/channel/UCccsY3sV_x81fzXSTDlGW4w";
export var PaymentSource;
(function (PaymentSource) {
    PaymentSource[PaymentSource["Online"] = 0] = "Online";
    PaymentSource[PaymentSource["OnLesson"] = 1] = "OnLesson";
})(PaymentSource || (PaymentSource = {}));
export var TimeOfDay;
(function (TimeOfDay) {
    TimeOfDay[TimeOfDay["Morning"] = 0] = "Morning";
    TimeOfDay[TimeOfDay["AllDay"] = 1] = "AllDay";
    TimeOfDay[TimeOfDay["Evening"] = 2] = "Evening";
})(TimeOfDay || (TimeOfDay = {}));
export var SkillLevel;
(function (SkillLevel) {
    SkillLevel[SkillLevel["All"] = -1] = "All";
    SkillLevel[SkillLevel["Low"] = 1] = "Low";
    SkillLevel[SkillLevel["Start"] = 2] = "Start";
    SkillLevel[SkillLevel["Middle"] = 3] = "Middle";
    SkillLevel[SkillLevel["High"] = 4] = "High";
})(SkillLevel || (SkillLevel = {}));
export var LessonType;
(function (LessonType) {
    LessonType[LessonType["Primary"] = 1] = "Primary";
    LessonType[LessonType["Stretching"] = 2] = "Stretching";
    LessonType[LessonType["Pointe"] = 3] = "Pointe";
    LessonType[LessonType["Parter"] = 4] = "Parter";
    LessonType[LessonType["Contemp"] = 5] = "Contemp";
})(LessonType || (LessonType = {}));
export const TimeOfDayPredicates = {
    [TimeOfDay.Morning]: (hour) => hour >= 0 && hour < 14,
    [TimeOfDay.AllDay]: () => true,
    [TimeOfDay.Evening]: (hour) => hour >= 14 && hour < 24,
};
export const weekdayVerbose = {
    0: "Неділя",
    1: "Понеділок",
    2: "Вівторок",
    3: "Середа",
    4: "Четвер",
    5: "П'ятниця",
    6: "Субота",
};
export const weekdayAbbreviated = {
    0: "Нд",
    1: "Пн",
    2: "Вт",
    3: "Ср",
    4: "Чт",
    5: "Пт",
    6: "Сб",
};
export const monthAsDatePartVerbose = {
    0: "січня",
    1: "лютого",
    2: "березня",
    3: "квітня",
    4: "травня",
    5: "червня",
    6: "липня",
    7: "серпня",
    8: "вересня",
    9: "жовтня",
    10: "листопада",
    11: "грудня",
};
export const levelRepresentation = {
    [SkillLevel.All]: "All",
    [SkillLevel.Low]: "START",
    [SkillLevel.Start]: "START+",
    [SkillLevel.Middle]: "MIDDLE",
    [SkillLevel.High]: "HIGH",
};
export var ViewerType;
(function (ViewerType) {
    ViewerType[ViewerType["Coach"] = 0] = "Coach";
    ViewerType[ViewerType["Student"] = 1] = "Student";
})(ViewerType || (ViewerType = {}));
export const dataOrderKey = "dataOrder";
export const typeRepresentation = {
    [LessonType.Primary]: " Балет",
    [LessonType.Stretching]: " Стретчинг",
    [LessonType.Pointe]: " Пуанти",
    [LessonType.Parter]: " Партер",
    [LessonType.Contemp]: "Контемп",
};
export var LessonAggregationPeriods;
(function (LessonAggregationPeriods) {
    LessonAggregationPeriods["Day"] = "Day";
    LessonAggregationPeriods["Week"] = "Week";
})(LessonAggregationPeriods || (LessonAggregationPeriods = {}));
export const placeOptions = [
    { id: 1, label: i18next.t("student~Станция метро") },
];
export const getTypeChoices = () => [
    { id: null, name: i18next.t("Выбрать") },
    { id: LessonType.Primary, name: i18next.t("Балет") },
    { id: LessonType.Stretching, name: i18next.t("Стретчинг") },
    { id: LessonType.Pointe, name: i18next.t("Пуанти") },
    { id: LessonType.Parter, name: i18next.t("Партер") },
    { id: LessonType.Contemp, name: i18next.t("Контемп") },
];
export const TimeOfDayOption = () => [
    { value: TimeOfDay.AllDay, label: "Всі групи" },
    { value: TimeOfDay.Morning, label: "Ранкові групи" },
    { value: TimeOfDay.Evening, label: "Вечірні групи" },
];
export const getTimeChoices = () => [
    { id: null, name: i18next.t("Выбрать") },
    { id: LessonTime.AllDay, name: i18next.t("Весь день") },
    { id: LessonTime.Morning, name: i18next.t("Ранок") },
    { id: LessonTime.Evening, name: i18next.t("Вечір") },
];
export const getTimeOfDay = () => [
    { id: LessonTime.Morning, name: i18next.t("Ранок") },
    { id: LessonTime.AllDay, name: i18next.t("День") },
    { id: LessonTime.Evening, name: i18next.t("Вечір") },
];
export const getTimeOfDaySelected = {
    [LessonTime.Morning]: TimeOfDay.Morning,
    [LessonTime.AllDay]: TimeOfDay.AllDay,
    [LessonTime.Evening]: TimeOfDay.Evening,
};
export const getLevelChoices = () => [
    { id: null, name: i18next.t("Вибрати") },
    { id: SkillLevel.Low, name: i18next.t("Старт") },
    { id: SkillLevel.Start, name: i18next.t("Старт+") },
    { id: SkillLevel.Middle, name: i18next.t("Середній") },
    { id: SkillLevel.High, name: i18next.t("Високий") },
];
export var ScheduleType;
(function (ScheduleType) {
    ScheduleType["Concerts"] = "concerts";
    ScheduleType["Events"] = "events";
    ScheduleType["Trial"] = "trial";
    ScheduleType["Group"] = "group";
    ScheduleType["Individual"] = "individual";
    ScheduleType["Checkout"] = "Checkout";
    ScheduleType["Online"] = "Online";
})(ScheduleType || (ScheduleType = {}));
export var CheckoutType;
(function (CheckoutType) {
    CheckoutType["Checkout"] = "redirect-to-checkout";
    CheckoutType["Default"] = "";
})(CheckoutType || (CheckoutType = {}));

import { isPhoneNumberValid } from '@utils/common';
import { i18n } from '@utils/i18n';
export const composeValidators = (validators) => {
    const validator = value => {
        for (const validate of validators) {
            const validationResult = validate(value);
            if (validationResult) {
                return validationResult;
            }
        }
        return null;
    };
    return validator;
};
export const emailValidator = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
        return i18n.t('Введіть коректний email');
    }
    return null;
};
export const phoneNumberValidator = value => {
    if (value && !isPhoneNumberValid(value)) {
        return i18n.t('Введіть номер телефону в форматі 380123456789');
    }
    return null;
};
export const required = value => {
    if (!value) {
        return i18n.t('Поле обязательно для заполнения');
    }
    return null;
};
export const validatePassword = value => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
    if (!passwordRegex.test(value)) {
        return i18n.t('Пароль має містити мінімум 8 символів враховуючи верхній і нижній регіст та цифри');
    }
    return null;
};

import * as classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { LayoutContainer } from "@components/student/LayoutContainer";
import { Link } from "@components/ui/Link";
import { publicContracts, siteAboutUs, siteBalletBarre, siteBalletChoreography, siteBalletContemporary, siteBalletForAdults, siteBalletForBeginners, siteBalletGroups, siteBalletInStudio, siteBalletIndividual, siteIndex, siteBalletNarodnoScenichnyyTanets, sitePointe, sitePrivacyPolicy, siteStretching, siteStretchingForChildren, } from "@urls";
import FacebookIcon1x from "@static/img/facebook-rounded-1x-min.png";
import FacebookIcon2x from "@static/img/facebook-rounded-2x-min.png";
import InstagramIcon1x from "@static/img/instagram-rounded-1x-min.png";
import InstagramIcon2x from "@static/img/instagram-rounded-2x-min.png";
import TelegramIcon1x from "@static/img/telegram-rounded-1x-min.png";
import TelegramIcon2x from "@static/img/telegram-rounded-2x-min.png";
import TikTokIcon1x from "@static/img/tiktok-rounded-1x-min.png";
import TikTokIcon2x from "@static/img/tiktok-rounded-2x-min.png";
import MaryBalletTopIcon from "@svg/maryBalletRedesignLogo.svg";
import dayjs from "dayjs";
import * as s from "./Footer.sss";
import { useCitiesQuery } from "@/graphql";
export const Footer = ({ className }) => {
    const { t } = useTranslation();
    const { data: { cities }, loading } = useCitiesQuery();
    const cityId = localStorage.getItem('cityId');
    const currentLocation = cities.find(({ id }) => id.toString() === cityId);
    if (loading) {
        return null;
    }
    if (localStorage.getItem('city')) {
        localStorage.removeItem('cityChoised');
        localStorage.removeItem('city');
        window.location.reload();
        return null;
    }
    return (React.createElement("div", { className: classNames(s.root, className) },
        React.createElement(LayoutContainer, { className: s.layout },
            React.createElement("div", { className: s.companyInfo },
                React.createElement("div", { className: s.iconAndName },
                    React.createElement(Link, { to: siteIndex },
                        React.createElement(MaryBalletTopIcon, null))),
                React.createElement("div", { className: s.description }, t("site~Mary Ballet - найбільша студія балету для дорослих в Києві")),
                React.createElement("div", { className: s.socialIcons },
                    React.createElement("a", { href: currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.instagram, target: "_blank" },
                        React.createElement("img", { className: "lazyload", "data-srcset": `${InstagramIcon1x} 1x, ${InstagramIcon2x} 2x`, alt: "intagram" })),
                    React.createElement("a", { href: currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.telegram, target: "_blank" },
                        React.createElement("img", { className: "lazyload", "data-srcset": `${TelegramIcon1x} 1x, ${TelegramIcon2x} 2x`, alt: "telegram" })),
                    React.createElement("a", { href: currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.facebook, target: "_blank" },
                        React.createElement("img", { className: "lazyload", "data-srcset": `${FacebookIcon1x} 1x, ${FacebookIcon2x} 2x`, alt: "facebook" })),
                    React.createElement("a", { href: currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.tiktok, target: "_blank" },
                        React.createElement("img", { className: "lazyload", "data-srcset": `${TikTokIcon1x} 1x, ${TikTokIcon2x} 2x`, alt: "TikTok" }))),
                React.createElement("div", { className: s.copyright },
                    "\u00A92019 \u2013 ",
                    dayjs().year(),
                    " Mary Ballet")),
            React.createElement("div", { className: s.topics },
                React.createElement("div", { className: s.topic },
                    React.createElement("div", { className: s.topicTitle }, t("site~Про компанію")),
                    React.createElement(Link, { to: siteAboutUs, onClick: () => window.scrollTo(0, 0), className: s.topicLink, theme: "clean", target: "_blank" }, t("site~Про нас")),
                    React.createElement(Link, { to: "#", className: s.topicLink, theme: "clean", target: "_blank" }, t("site~Місія")),
                    React.createElement(Link, { to: "#", className: s.topicLink, theme: "clean", target: "_blank" }, t("site~Засновники")),
                    React.createElement(Link, { to: "#", className: s.topicLink, theme: "clean", target: "_blank" }, t("site~Кар'єра"))),
                React.createElement("div", { className: s.topic },
                    React.createElement("div", { className: s.topicTitle }, t("site~Напрями")),
                    React.createElement(Link, { className: s.topicLink, theme: "clean", to: siteBalletForAdults, target: "_blank" }, t("site~Балет для дорослих")),
                    React.createElement(Link, { className: s.topicLink, theme: "clean", to: siteBalletForBeginners, target: "_blank" }, t("site~Балет для початківців")),
                    React.createElement(Link, { className: s.topicLink, theme: "clean", to: siteBalletIndividual, target: "_blank" }, t("site~Індивідуальні заняття балетом")),
                    React.createElement(Link, { className: s.topicLink, theme: "clean", to: siteBalletGroups, target: "_blank" }, t("site~Групові заняття балетом")),
                    React.createElement(Link, { className: s.topicLink, theme: "clean", to: siteBalletChoreography, target: "_blank" }, t("site~Класична хореографія")),
                    React.createElement(Link, { className: s.topicLink, theme: "clean", to: siteBalletBarre, target: "_blank" }, t("site~Барре")),
                    React.createElement(Link, { className: s.topicLink, theme: "clean", to: siteBalletContemporary, target: "_blank" }, t("site~Контемпорарі")),
                    React.createElement(Link, { className: s.topicLink, theme: "clean", to: siteBalletNarodnoScenichnyyTanets, target: "_blank" }, t("site~Народно-сценічний танець"))),
                React.createElement("div", { className: s.topic },
                    React.createElement("div", { className: s.topicTitle }, t("site~Світ Mary Ballet")),
                    React.createElement(Link, { to: "#", className: s.topicLink, theme: "clean", target: "_blank" }, t("site~Новини")),
                    React.createElement(Link, { to: "#", className: s.topicLink, theme: "clean", target: "_blank" }, t("site~Майстер-класи")),
                    React.createElement(Link, { to: "#", className: s.topicLink, theme: "clean", target: "_blank" }, t("site~Заходи")),
                    React.createElement(Link, { to: "#", className: s.topicLink, theme: "clean", target: "_blank" }, t("site~Магазин"))),
                React.createElement("div", { className: s.topic },
                    React.createElement("div", { className: s.topicTitle }, t("site~Допомога")),
                    React.createElement(Link, { className: s.topicLink, theme: "clean", to: sitePrivacyPolicy, target: "_blank" }, t("site~Політика конфіденційності")),
                    React.createElement(Link, { className: s.topicLink, theme: "clean", to: publicContracts, target: "_blank" }, t("site~Публічний договір")),
                    React.createElement(Link, { className: s.topicLink, theme: "clean", to: siteStretching, target: "_blank" }, t("site~Стретчинг")),
                    React.createElement(Link, { className: s.topicLink, theme: "clean", to: sitePointe, target: "_blank" }, t("site~Пуанти")),
                    React.createElement(Link, { className: s.topicLink, theme: "clean", to: siteStretchingForChildren, target: "_blank" }, t("site~Стретчинг для дітей")),
                    React.createElement(Link, { className: s.topicLink, theme: "clean", to: siteBalletInStudio, target: "_blank" }, t("site~Заняття в студіях")))))));
};

import * as React from "react";
export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        return { hasError: true };
    }
    render() {
        const { state, children } = this.props;
        if (this.state.hasError) {
            return React.createElement("h1", null, "Something went wrong.");
        }
        try {
            return children;
        }
        catch (e) {
            return "ufff";
        }
    }
}

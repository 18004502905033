var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as classNames from "classnames";
import * as React from "react";
import * as s from "./Button.sss";
export const Button = (_a) => {
    var { className, theme = "primaryPurple", type = "button", size = "M", id } = _a, props = __rest(_a, ["className", "theme", "type", "size", "id"]);
    return (React.createElement("button", Object.assign({ className: classNames(s.root, className, { [s.themed]: theme !== "clean" }, s[theme], s[`size${size}`]), id: id, type: type }, props)));
};
